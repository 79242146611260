import axios from "axios";
import { authFormPost, authPost } from "../../common/redux/ApiClient";

const baseUrl = "http://34.87.51.38/w188_api";

export function walletUICall(queryParams) {
  return authPost(`${baseUrl}/Member2.ashx`, queryParams);
}

export const atmDepositRequest = (queryParams) => {
  return authFormPost(
    `${window.location.protocol}//media.lotto4896.com/api/deposit.php`,
    queryParams
  );
};
export function walletUICall2(queryParams) {
  return authPost(`${baseUrl}/Member3.ashx`, queryParams);
}
