import { Field, Form, Formik } from "formik";
import { useIntl } from "react-intl";
import {
  Input,
  Input2,
} from "../../../../common/components/control/InputField";
import { addBankSchema } from "../../../yupSchema/addBankSchema";
import { useState } from "react";
import { useEffect } from "react";
import { CheckIsNull } from "../../../../../utils/CommonHelper";
import { Button, Drawer } from "@mui/material";
import { FiArrowLeft } from "react-icons/fi";

export const AddBankForm = ({ formikRef, saveValues, bankList }) => {
  const intl = useIntl();
  const [bankName, setBankName] = useState("");
  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    if (bankList.length > 0) {
      setBankName(bankList[0].bankname);
    }
  }, [bankList]);
  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };
  return (
    <Formik
      initialValues={{
        fullName: "",
        accountNum: "",
        otherBankName: "",
      }}
      innerRef={formikRef}
      validationSchema={addBankSchema(intl)}
      enableReinitialize={true}
      validate={(values, formikHelpers) => {
        const errors = { otherBankName: "" };
        if (bankName === "Other" && CheckIsNull(values.otherBankName)) {
          errors.otherBankName = intl.formatMessage({
            id: "otherBankNameRequired",
          });
          return errors;
        }
      }}
      onSubmit={(values, formikHelpers) => {
        const queryParams = {
          action: "addbankcard",
          bankname: bankName === "Other" ? values.otherBankName : bankName,
          accountnum: values.accountNum,
          accountname: values.fullName,
        };

        saveValues(queryParams);
      }}
    >
      {({
        values,
        handleSubmit,
        setFieldValue,
        touched,
        errors,
        setFieldError,
      }) => (
        <Form>
          <div className="mb-2">
            <label className="mb-2 text-input-label">
              {intl.formatMessage({ id: "bankName" })}
            </label>
            <div>
              <input
                type="text"
                className="form-control text-font2"
                value={bankName}
                onClick={toggleDrawer}
                readOnly
              />
              <Drawer anchor="bottom" open={drawerOpen} onClose={toggleDrawer}>
                <div
                  style={{
                    backgroundColor: "#1c1e23",
                    padding: "16px",
                    height: "60vh",
                    overflowY: "auto",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Button onClick={toggleDrawer}>
                      <FiArrowLeft style={{ color: "white" }} />
                    </Button>
                    <div
                      style={{ flex: 7, color: "white" }}
                      className="text-font4"
                    >
                      {intl.formatMessage({ id: "bankName" })}
                    </div>
                    <div style={{ flex: 2 }}></div>
                  </div>

                  <div
                    style={{
                      color: "white",
                      cursor: "pointer",
                      width: "100%",
                      fontSize: "13px",
                      paddingTop: "10px",
                    }}
                  >
                    {bankList.map((bank, index) => (
                      <div
                        key={index}
                        onClick={() => {
                          setBankName(bank.bankname);
                          toggleDrawer();
                        }}
                        style={{
                          width: "100%",
                          height: "50px",
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        {bank.bankname}
                      </div>
                    ))}
                  </div>
                </div>
              </Drawer>
            </div>
          </div>
          {bankName === "Other" && (
            <div>
              <label
                className="mb-2 text-input-label"
                style={{ color: "white" }}
              >
                {intl.formatMessage({ id: "otherBankName" })}
              </label>
              <div className="mb-2 position-relative">
                <Field
                  name="otherBankName"
                  component={Input}
                  placeholder={intl.formatMessage({ id: "otherBankName" })}
                  autoComplete="off"
                  type="text"
                  isUpperCaseOnly={true}
                />
              </div>
            </div>
          )}
          <label className="mb-2 text-input-label" style={{ color: "white" }}>
            {intl.formatMessage({ id: "fullName" })}
          </label>
          <div className="mb-2 position-relative">
            <Field
              name="fullName"
              component={Input2}
              placeholder={intl.formatMessage({ id: "fullName" })}
              autoComplete="off"
              type="text"
              isUpperCaseOnly={true}
            />
          </div>
          <label className="mb-2 text-input-label" style={{ color: "white" }}>
            {intl.formatMessage({ id: "accountNumber" })}
          </label>
          <div className="mb-2 position-relative">
            <Field
              name="accountNum"
              component={Input}
              placeholder={intl.formatMessage({ id: "accountNumber" })}
              autoComplete="off"
              type="number"
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};
