import { useRef, useState } from "react";
import { useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import SweetAlert2 from "react-sweetalert2";
import * as actions from "../../redux/WalletAction";
import { Modal } from "../../../common/components/Modal1";
import { EditStatusDialog } from "./EditStatusDialog";

export const HistoryDetails = ({
  isHistoryDetailsVisible,
  setHistoryDetailsVisible,
  onClose,
  index,
  reportType,
  reportTypeDesc,
  dayType,
  viewType,
}) => {
  const intl = useIntl();
  const [swalProps, setSwalProps] = useState({});
  const dispatch = useDispatch();
  const dismissBtnRef = useRef();
  const [editButton, setEditButton] = useState(false);
  const [currentStatus, setCurrentStatus] = useState("");
  const [isEditModalVisible, setEditModalVisible] = useState(false);
  const walletState = useSelector(({ wallet }) => wallet, shallowEqual);

  useEffect(() => {
    const queryParams = {
      action: "topupwithdrawdetail",
      reporttype: reportType,
      itemid: `${index}`,
    };
    dispatch(actions.topupWithdrawDetailsList(queryParams)).then((resp) => {
      resp = resp.data;
      setCurrentStatus(resp.status);
    });
  }, [index, reportType]);
  const handleClose = () => {
    onClose();
  };

  return (
    <div style={{ width: "100%", margin: "0 auto" }}>
      <Modal
        modalId="historyDetailsModal"
        title={intl.formatMessage({ id: "historyDetails" })}
        dismissBtnRef={dismissBtnRef}
        isVisible={isHistoryDetailsVisible}
        onClose={handleClose}
      >
        <SweetAlert2
          customClass="custom-swal"
          onResolve={() => {
            setSwalProps({});
          }}
          {...swalProps}
        />
        <EditStatusDialog
          setSwalProps={setSwalProps}
          isVisible={isEditModalVisible}
          reportType={reportType}
          index={index}
          onClose={() => {
            setEditModalVisible(false);
            setEditButton(false);
            setHistoryDetailsVisible(false);
            const queryParams = {
              action: "topupwithdrawpendinglist",
              days: dayType,
              viewtype: viewType,
            };
            const queryParams1 = {
              action: "topupwithdrawdetail",
              reporttype: reportType,
              itemid: `${index}`,
            };
            dispatch(actions.topupWithdrawPendingList(queryParams));
            dispatch(actions.topupWithdrawDetailsList(queryParams1)).then(
              (resp) => {
                resp = resp.data;
                setCurrentStatus(resp.status);
              }
            );
          }}
        />
        {walletState && walletState.topupWithdrawDetailsList && (
          <div>
            <table style={{ width: "96%", margin: "0 auto" }}>
              {[
                {
                  label: intl.formatMessage({ id: "requestDate" }),
                  value: walletState.topupWithdrawDetailsList.requestdate,
                },
                {
                  label: intl.formatMessage({ id: "reportType" }),
                  value: reportTypeDesc,
                },
                {
                  label: intl.formatMessage({ id: "amount" }),
                  value: walletState.topupWithdrawDetailsList.amount,
                },
                {
                  label: intl.formatMessage({ id: "status" }),
                  value:
                    editButton === true &&
                    (reportType === "withdraw" ||
                      reportType === "manualtopup") &&
                    walletState.topupWithdrawDetailsList.status ===
                      "REQUEST" ? (
                      <select
                        value={currentStatus}
                        onChange={(e) => {
                          setCurrentStatus(e.target.value);
                        }}
                        className="w-100"
                        style={{
                          backgroundColor: "#1c1e23",
                          color: "white",
                          border: "none",
                        }}
                      >
                        <option value="request">REQUEST</option>
                        <option value="cancel">CANCEL</option>
                      </select>
                    ) : (
                      walletState.topupWithdrawDetailsList.status
                    ),
                },
                {
                  label: intl.formatMessage({ id: "detail" }),
                  value: walletState.topupWithdrawDetailsList.detail
                    ? walletState.topupWithdrawDetailsList.detail
                        .split("\n")
                        .map((line, index) => <div key={index}>{line}</div>)
                    : intl.formatMessage({ id: "noDetailsAvailable" }),
                },
                ...(reportType !== "gatewaytopup"
                  ? [
                      {
                        label: intl.formatMessage({ id: "receipt" }),
                        value: (
                          <img
                            src={
                              walletState.topupWithdrawDetailsList.receiptimage
                            }
                            alt="Receipt"
                            className="img-fluid"
                          />
                        ),
                      },
                    ]
                  : []),
              ].map((row, index) => (
                <tr
                  key={index}
                  style={{
                    border: "1px solid #ccc",
                  }}
                >
                  <td className="text-nowrap px-2 py-2">{row.label}</td>
                  <td className="px-2">:</td>
                  <td>{row.value}</td>
                </tr>
              ))}
            </table>
          </div>
        )}
        {reportType === "gatewaytopup" ? (
          <>
            <div className="p-3 d-flex justify-content-center">
              <button
                className="btn btn-primary px-3"
                onClick={() => {
                  onClose();
                }}
              >
                {intl.formatMessage({ id: "close" })}
              </button>
            </div>
          </>
        ) : (
          <>
            {editButton === true ? (
              <div className="p-3 d-flex justify-content-evenly">
                <button
                  className="btn btn-success px-3"
                  onClick={() => {
                    setEditModalVisible(true);
                  }}
                  disabled={
                    currentStatus === "REQUEST" || currentStatus === "request"
                  }
                >
                  {intl.formatMessage({ id: "submit" })}
                </button>
                <button
                  className="btn btn-danger px-3"
                  onClick={() => {
                    setEditButton(false);
                  }}
                >
                  {intl.formatMessage({ id: "cancel" })}
                </button>
              </div>
            ) : walletState &&
              walletState.topupWithdrawDetailsList &&
              walletState.topupWithdrawDetailsList.status === "CANCEL" ? (
              <>
                <div className="p-3 d-flex justify-content-center">
                  <button
                    className="btn btn-primary px-3"
                    onClick={() => {
                      onClose();
                    }}
                  >
                    {intl.formatMessage({ id: "close" })}
                  </button>
                </div>
              </>
            ) : (
              <div className="p-3 d-flex justify-content-center">
                <button
                  className="btn btn-primary px-3"
                  onClick={() => {
                    setEditButton(true);
                  }}
                >
                  {intl.formatMessage({ id: "edit" })}
                </button>
              </div>
            )}
          </>
        )}
      </Modal>
    </div>
  );
};
