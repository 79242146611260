import { useRef, useState } from "react";
import { Modal } from "../../common/components/Modal";
import { useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { CopyToClipboard } from "../../../utils/CommonHelper";
// import { ApiStatusTypeEnum } from "../../../../../enumeration/ApiStatusTypeEnum";
// import { ResolveArrayNull } from "../../../../../utils/CommonHelper";
import { Dialog } from "@mui/material";
import SweetAlert2 from "react-sweetalert2";
import { useAuth } from "./AuthInit";

export const ChatDialog = ({}) => {
  const intl = useIntl();
  const dismissBtnRef = useRef();
  const [swalProps, setSwalProps] = useState({});
  const formikRef = useRef();
  const [open, setOpen] = useState(false);
  const [chatLink, setChatLink] = useState("");
  const dispatch = useDispatch();
  const { auth } = useAuth();

  const handleClose = () => {
    setOpen(false);
  };

  const handlNew = () => {
    window.open(chatLink);
    setOpen(false);
  };

  const handleWhatsappClick = () => {
    const customerServiceLink = `https://wa.me/+60176814148?text=Hi%20Lotto4890%20Customer%20Service%20`;
    setChatLink(customerServiceLink);
    if (sessionStorage.getItem("platform") === "mobile") {
      console.log(
        `customerservice: https://wa.me/+60176814148?text=Hi%20Lotto4890%20Customer%20Service%20`
      );
      sessionStorage.setItem("customerServiceLink", customerServiceLink);
    } else {
      const isSafari = /^((?!chrome|android).)*safari/i.test(
        navigator.userAgent
      );
      if (isSafari) {
        setOpen(true);
      } else {
        window.open(customerServiceLink);
      }
    }
  };

  const handleTelegramClick = () => {
    const customerServiceLink = "https://t.me/atLotto4896";
    setChatLink(customerServiceLink);
    if (sessionStorage.getItem("platform") === "mobile") {
      console.log(`customerservice: https://t.me/atLotto4896`);
      sessionStorage.setItem("customerServiceLink", customerServiceLink);
    } else {
      const isSafari = /^((?!chrome|android).)*safari/i.test(
        navigator.userAgent
      );
      if (isSafari) {
        setOpen(true);
      } else {
        window.open(customerServiceLink);
      }
    }
  };
  return (
    <Modal
      modalId="chatModal"
      title={intl.formatMessage({ id: "liveChat" })}
      dismissBtnRef={dismissBtnRef}
    >
      <SweetAlert2
        onResolve={() => {
          setSwalProps({});
        }}
        backdrop={false}
        {...swalProps}
        customClass="custom-swal"
      />
      <div className="m-3">
        <div className="d-flex align-items-center">
          <div>
            <i
              className="bi bi-telegram me-2"
              style={{
                fontSize: "3rem",
                color: "#0080FF",
              }}
            />
          </div>
          <div className="d-flex flex-column">
            <div
              style={{
                color: "#0080FF",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={handleTelegramClick}
            >
              {"https://t.me/atLotto4896..."}
            </div>
            <div>
              {`+6017-9916753`}{" "}
              <i
                className="bi bi-copy"
                onClick={() => {
                  if (sessionStorage.getItem("platform") === "mobile") {
                    console.log(`clipboard: +6017-9916753`);
                    CopyToClipboard(`+6017-9916753`, setSwalProps, intl);
                  } else {
                    CopyToClipboard(`+6017-9916753`, setSwalProps, intl);
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <div>
            <i
              className="bi bi-whatsapp me-2"
              style={{ fontSize: "3rem", color: "#00FF00" }}
            />
          </div>
          <div className="d-flex flex-column">
            <div
              style={{
                color: "#0080FF",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={handleWhatsappClick}
            >
              {"https://wa.me/+60176814148..."}
            </div>
            <div>
              {`+6017-6814148`}{" "}
              <i
                className="bi bi-copy"
                onClick={() => {
                  if (sessionStorage.getItem("platform") === "mobile") {
                    console.log(`clipboard: +6017-6814148`);
                    CopyToClipboard(`+6017-6814148`, setSwalProps, intl);
                  } else {
                    CopyToClipboard(`+6017-6814148`, setSwalProps, intl);
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <Dialog open={open} onClose={handleClose}>
        <div
          className="text-font3"
          style={{
            padding: "20px",
            backgroundColor: "#1c1e23",
            color: "white",
          }}
        >
          <p> {intl.formatMessage({ id: "openCustomerService" })}</p>
          <div
            style={{ color: "#f5be09", textAlign: "end" }}
            onClick={handlNew}
          >
            {intl.formatMessage({ id: "confirm" })}
          </div>
        </div>
      </Dialog>
    </Modal>
  );
};
