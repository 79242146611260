import { createIntl, createIntlCache, useIntl } from "react-intl";
import { messages } from "./messages";
import { LOCALES } from "./locales";

// 'intl' service singleton reference
let intl;
let initialState = {
  selectedLang: "en",
};
export const I18N_CONFIG_KEY = "i18nConfig";
const allMessages = {
  [LOCALES.ENGLISH]: messages[LOCALES.ENGLISH],
  [LOCALES.CHINESE]: messages[LOCALES.CHINESE],
  [LOCALES.MALAY]: messages[LOCALES.MALAY],
  [LOCALES.INDO]: messages[LOCALES.INDO],
};
export function getConfig() {
  // browser setting language
  let browserLanguage =
    window.navigator.userLanguage || window.navigator.language;

  if (browserLanguage !== null || browserLanguage !== "") {
    browserLanguage = browserLanguage.substring(0, 2);
    if (allMessages[browserLanguage]) {
      initialState.selectedLang = browserLanguage;
    }
  }

  const ls = localStorage.getItem(I18N_CONFIG_KEY);

  if (ls) {
    try {
      return JSON.parse(ls);
    } catch (er) {
      console.error(er);
    }
  }
  return initialState;
}
export function IntlGlobalProvider({ children }) {
  intl = useIntl(); // Keep the 'intl' service reference
  return children;
}

// Getter function to expose the read-only 'intl' service
export function appIntl() {
  const locale = getConfig()["selectedLang"];
  const messages = allMessages[locale];

  const cache = createIntlCache();
  const intl = createIntl({ locale, messages }, cache);

  return intl;
}
