import * as Yup from "yup";

export const forgotPasswordStep1Schema = (intl) => {
  return Yup.object().shape({
    verifyCode: Yup.string()
      .nullable(false)
      .required(intl.formatMessage({ id: "verifyCodeRequired" })),
  });
};

export const forgotPasswordStep3Schema = (intl) => {
  return Yup.object().shape({
    newPassword: Yup.string()
      .nullable(false)
      .required(intl.formatMessage({ id: "newPasswordRequired" })),
    confirmPassword: Yup.string()
      .nullable(false)
      .required(intl.formatMessage({ id: "confirmPasswordRequired" })),
    otpCode: Yup.string()
      .nullable(false)
      .required(intl.formatMessage({ id: "otpCodeRequired" })),
  });
};
