import { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import Skeleton from "react-loading-skeleton";
import { Modal } from "./Modal1";
import { toAbsoluteUrl } from "../../../utils/CommonHelper";

export const DownloadDetail = ({
  isDownloadModalVisible,
  setDownloadDetailsVisible,
  setSwalProps,
  onClose,
}) => {
  const intl = useIntl();
  const dismissBtnRef = useRef();
  const dispatch = useDispatch();
  const toggleDownload = () => {
    if (sessionStorage.getItem("platform") === "mobile") {
      navigate(
        "https://dl.dropboxusercontent.com/scl/fi/tn4as1hyyqyqzq9paoaum/lotto4896_m.apk?rlkey=certlwxbyvqu6gb5r6y0r2dld&st=qfs0p0k6&dl=0"
      );
    } else {
      window.open(
        "https://dl.dropboxusercontent.com/scl/fi/tn4as1hyyqyqzq9paoaum/lotto4896_m.apk?rlkey=certlwxbyvqu6gb5r6y0r2dld&st=qfs0p0k6&dl=0"
      );
    }
  };
  const handleClose = () => {
    onClose();
  };
  return (
    <Modal
      modalId="downloadModal"
      title={intl.formatMessage({ id: "downloadapp" })}
      dismissBtnRef={dismissBtnRef}
      isVisible={isDownloadModalVisible}
      modalSize="modal-lg"
      onClose={handleClose}
    >
      <div className="my-2 text-center mx-auto" style={{ color: "#f5be09" }}>
        <img
          src={toAbsoluteUrl(`/image/download/1.png`)}
          alt="clickIcon"
          className=" my-3"
        />
        <div>{intl.formatMessage({ id: "clickTheIcon" })}</div>
        <img
          src={toAbsoluteUrl(`/image/download/2.png`)}
          alt="drawing"
          className=" my-3"
        />
        <div>{intl.formatMessage({ id: "clickDestinyDrawings" })}</div>
        <img
          src={toAbsoluteUrl(`/image/download/3.png`)}
          alt="duapekgong"
          className=" my-3"
        />
        <div>{intl.formatMessage({ id: "clickDuaPekGong" })}</div>
        <img
          src={toAbsoluteUrl(`/image/download/4.png`)}
          alt="search"
          className=" my-3"
        />
        <div>{intl.formatMessage({ id: "inputAndSearch" })}</div>
        <img
          src={toAbsoluteUrl(`/image/download/5.png`)}
          alt="login"
          className=" my-3"
        />
        <div>{intl.formatMessage({ id: "reachLoginPage" })}</div>
      </div>
      <div
        className="text-font2 ws-nowrap"
        style={{
          border: "1px solid #f5be09",
          borderRadius: "5px",
          padding: "5px",
          color: "#f5be09",
          width: "50%",
          margin: "10px auto",
          textAlign: "center",
        }}
        onClick={toggleDownload}
      >
        {intl.formatMessage({ id: "downloadapp" })}
      </div>
    </Modal>
  );
};
