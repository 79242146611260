import { useState } from "react";
import { useRef } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import SweetAlert2 from "react-sweetalert2";
import { useEffect } from "react";
import * as actions from "../../../redux/WalletAction";
import * as masterActions from "../../../../common/redux/MasterLayoutAction";
import { Link } from "react-router-dom";
import { WithdrawForm } from "./WithdrawForm";
import { ResolveArrayNull } from "../../../../../utils/CommonHelper";
import Skeleton from "react-loading-skeleton";
import { ApiStatusTypeEnum } from "../../../../../enumeration/ApiStatusTypeEnum";
import { AddBank } from "../../bankMangement/AddBank";
import { callMemberBalance } from "../../../../home/redux/HomeAction";

export const WithdrawDialog = () => {
  const [selectedBank, setSelectedBank] = useState(0);
  const [swalProps, setSwalProps] = useState({});
  const intl = useIntl();
  const formikRef = useRef();
  const dispatch = useDispatch();
  const walletState = useSelector(({ wallet }) => wallet, shallowEqual);

  const saveValues = (queryParams) => {
    const newQuery = {
      ...queryParams,
      action: "withdrawrequest",
      bankitemid:
        walletState.bankCardList.memberBankCardLists[selectedBank].itemid,
    };
    dispatch(actions.withdrawRequestList(newQuery)).then((resp) => {
      resp = resp.data;
      if (parseInt(resp.code) === ApiStatusTypeEnum.SUCCESS) {
        const queryParams = {
          action: "memberbalance",
        };
        dispatch(callMemberBalance(queryParams));
        setSwalProps({
          show: true,
          icon: "success",
          title: intl.formatMessage({ id: "success" }),
          text: intl.formatMessage({ id: "withdrawRequestSendSuccessfully" }),
          onResolve: () => {
            setSwalProps({});
            formikRef.current.resetForm();
            const queryParams1 = {
              action: "withdrawui",
            };
            dispatch(actions.withdrawUIList(queryParams1));
          },
        });
      } else {
        setSwalProps({
          show: true,
          icon: "error",
          title: intl.formatMessage({ id: "error" }),
          text: resp.message,
        });
      }
    });
  };

  useEffect(() => {
    const queryParams = {
      action: "memberbankcardlist",
    };
    const queryParams1 = {
      action: "withdrawui",
    };
    dispatch(actions.bankCardList(queryParams));
    dispatch(actions.withdrawUIList(queryParams1)).then((resp) => {
      if (resp.data?.lastrejectreason) {
        const title = `${intl.formatMessage({
          id: "lastwithdrawrejected",
        })} <span style="color: #f5be09;">${resp.data.lastrequestdate} </span>`;
        const text = resp.data.lastrejectreason;
        setSwalProps({
          show: true,
          icon: "error",
          title,
          text,
        });
      }
    });
  }, []);

  return (
    <>
      <SweetAlert2
        customClass="custom-swal"
        onResolve={() => {
          setSwalProps({});
        }}
        {...swalProps}
      />
      {walletState &&
        walletState.bankCardList &&
        walletState.withdrawUIList && (
          <div style={{ width: "95%", margin: "0 auto" }}>
            <div>
              <AddBank
                selectedBank={selectedBank}
                setSelectedBank={setSelectedBank}
              />
            </div>

            <div style={{ color: "white" }}>
              <WithdrawForm
                formikRef={formikRef}
                saveValues={saveValues}
                mainWallet={walletState.withdrawUIList.balance}
                floatingBalance={walletState.withdrawUIList.withdrawlock}
              />
            </div>
            <button
              type="button"
              style={{ color: "black", backgroundColor: "#f5be09" }}
              className="btn btn-prim fw-bold px-3 w-100 my-4"
              onClick={() => formikRef.current.submitForm()}
              disabled={
                walletState.actionsLoading ||
                ResolveArrayNull(walletState.bankCardList?.memberBankCardLists)
                  .length <= 0
              }
            >
              <div className="d-flex align-items-center justify-content-center text-font3">
                {walletState.actionsLoading && (
                  <div
                    className="spinner-border text-dark"
                    role="status"
                    style={{ scale: "0.75" }}
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                )}
                {intl.formatMessage({ id: "submit" })}
              </div>
            </button>
          </div>
        )}
    </>
  );
};
