/* eslint-disable jsx-a11y/anchor-is-valid */
import { Route, Routes } from "react-router-dom";
import { createContext } from "react";
import { Home } from "../../modules/home/component/Home";

const HomeContext = createContext();

const HomeWrapper = () => {
  const value = {};

  return (
    <HomeContext.Provider value={value}>
      <Routes>
        <Route path="" element={<Home />} />
      </Routes>
    </HomeContext.Provider>
  );
};

export { HomeWrapper };
{
  /* <Routes>
    <Route element={<Outlet />}>
      <Route path="*" element={<Navigate to="/login" />} />
      <Route
        path="/login"
        element={<LoginRegisterDialog initialTab={LoginTab.LOGIN} />}
      />
      <Route
        path="/register"
        element={<LoginRegisterDialog initialTab={LoginTab.REGISTER_OPTION} />}
      />
      <Route
        path="/register/:refferalCode"
        element={<LoginRegisterDialog initialTab={LoginTab.REGISTER_OPTION} />}
      />
      <Route index element={<LoginRegisterDialog />} />
    </Route>

    <Route element={<Outlet />}></Route>
  </Routes> */
}
