import { useIntl } from "react-intl";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { NavBarConst } from "../../../constants/NavBarConst";
import { createContext, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { toAbsoluteUrl } from "../../../utils/CommonHelper";
import { useAuth } from "../../auth/components/AuthInit";
import SweetAlert2 from "react-sweetalert2";
import { FaStar } from "react-icons/fa6";
import { CgCloseO } from "react-icons/cg";
import { DownloadDetail } from "./Download";
const MasterLayoutContext = createContext();

export const MasterLayout = () => {
  const intl = useIntl();
  const { logout } = useAuth();
  const [swalProps, setSwalProps] = useState({});
  const [isVisible, setIsVisible] = useState(true);
  const [downloadDetailsVisible, setDownloadDetailsVisible] = useState(false);
  const masterLayoutState = useSelector(
    ({ masterLayout }) => masterLayout,
    shallowEqual
  );
  const navigate = useNavigate();
  const value = {};
  const imageUrl = toAbsoluteUrl("/image/logo/logo1.png");
  useEffect(() => {
    const handleTokenChange = () => {
      setSwalProps({
        show: true,
        icon: "error",
        text: "Please avoid logging in with the same account\n请避免使用同一账号登入\nSila elakkan log masuk dengan  akaun yan sama",
      });
    };

    window.addEventListener("sessionLost", handleTokenChange);

    return () => {
      window.removeEventListener("sessionLost", handleTokenChange);
    };
  }, []);
  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };
  const toggleDownload = () => {
    setDownloadDetailsVisible(true);
  };

  // useEffect(() => {
  //   const isMobile = sessionStorage.getItem("platform") === "mobile";
  //   const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  //   if (isMobile || isSafari) {
  //     document.querySelector(".bottom-download").style.display = "none";

  //     return () => {
  //       document.querySelector(".bottom-download").style.display = "flex";
  //     };
  //   }
  // });

  return (
    <>
      <SweetAlert2
        {...swalProps}
        customClass="custom-swal"
        onResolve={() => {
          setSwalProps({});
          logout();
        }}
      />
      <DownloadDetail
        setSwalProps={setSwalProps}
        isDownloadModalVisible={downloadDetailsVisible}
        setDownloadDetailsVisible={setDownloadDetailsVisible}
        onClose={() => setDownloadDetailsVisible(false)}
      />
      <MasterLayoutContext.Provider value={value}>
        <div className="masterPage-content">
          <Outlet />
        </div>
        {/* {sessionStorage.getItem("platform") !== "telegram" && (
          <div
            className="bottom-download"
            style={{
              height: "50px",
              backgroundColor: "rgb(0,0,0,0.8)",
              position: "fixed",
              bottom: 0,
              width: "100%",
              zIndex: 998,
              display: isVisible ? "flex" : "none",
              alignItems: "center",
              paddingLeft: "5px",
              paddingRight: "5px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <div>
                <img
                  src={imageUrl}
                  alt="Logo"
                  style={{ width: "30px", height: "auto" }}
                />
              </div>

              <div style={{ textAlign: "center", margin: "0 10px" }}>
                <div
                  className="text-font1 ws-nowrap"
                  style={{ display: "flex" }}
                >
                  <div style={{ color: "#f5be09", paddingRight: "5px" }}>
                    Lotto 4986
                  </div>
                  <div style={{ color: "#f5be09" }}>
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                  </div>
                </div>
                <div
                  className="text-font1 ws-nowrap"
                  style={{ display: "flex" }}
                >
                  <div style={{ color: "white", paddingRight: "5px" }}>
                    Official
                  </div>
                  <div style={{ color: "white", paddingRight: "5px" }}>
                    No Ad
                  </div>
                  <div style={{ color: "white", paddingRight: "5px" }}>
                    No Plugin
                  </div>
                  <div style={{ color: "white" }}>Anti-hijacking</div>
                </div>
              </div>
              <div
                className="text-font2 ws-nowrap"
                style={{
                  border: "1px solid #f5be09",
                  borderRadius: "5px",
                  padding: "5px",
                  color: "#f5be09",
                }}
                onClick={toggleDownload}
              >
                {intl.formatMessage({ id: "downloadapp" })}
              </div>
              <div style={{ color: "white", paddingLeft: "5px" }}>
                <CgCloseO onClick={toggleVisibility} />
              </div>
            </div>
          </div>
        )} */}
      </MasterLayoutContext.Provider>
    </>
  );
};
