import { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../redux/LotteryAction";
import { ApiStatusTypeEnum } from "../../../../../enumeration/ApiStatusTypeEnum";
import { Modal } from "../../../../common/components/Modal1";
import Skeleton from "react-loading-skeleton";
import { callMemberBalance } from "../../../../home/redux/HomeAction";

export const LotteryDetailConst = {
  BASIC: "basic",
  ADVANCE: "advance",
};

export const LotteryOnHoldDetail = ({
  isLotteryOnHoldDetailsVisible,
  setLotteryOnHoldDetailsVisible,
  lotteryItemID,
  setSwalProps,
  viewType,
  onClose,
}) => {
  const intl = useIntl();
  const [lotteryDetailMode, setLotteryDetailMode] = useState(
    LotteryDetailConst.BASIC
  );
  const dismissBtnRef = useRef();
  const lotteryState = useSelector(({ lottery }) => lottery, shallowEqual);

  const dispatch = useDispatch();

  useEffect(() => {
    if (lotteryItemID) {
      const queryParams = {
        action: "seasononholddetail",
        effrelateid: lotteryItemID,
      };
      dispatch(actions.getLotteryOnHoldDetail(queryParams));
      setLotteryDetailMode(LotteryDetailConst.BASIC);
    }
  }, [lotteryItemID]);

  const voidLottery = () => {
    const queryParams = {
      action: "voidseasononhold",
      effrelateid: lotteryItemID,
    };
    dispatch(actions.voidSeasonOnHold(queryParams)).then((resp) => {
      resp = resp.data;

      if (parseInt(resp.code) === ApiStatusTypeEnum.SUCCESS) {
        const queryParams = {
          action: "seasononholdlist",
          viewtype: viewType,
        };
        dispatch(actions.callSeasonOnHoldList(queryParams)).then(() => {
          const queryParams = {
            action: "memberbalance",
          };
          dispatch(callMemberBalance(queryParams));
        });
        const queryParams1 = {
          action: "seasononholddetail",
          effrelateid: lotteryItemID,
        };
        dispatch(actions.getLotteryOnHoldDetail(queryParams1));
        setSwalProps({
          show: true,
          icon: "success",
          title: intl.formatMessage({ id: "success" }),
          text: intl.formatMessage({ id: "voidSuccessfully" }),
          onResolve: () => {
            setSwalProps({});
            dismissBtnRef.current.click();
          },
        });
      } else {
        setSwalProps({
          show: true,
          icon: "error",
          title: intl.formatMessage({ id: "error" }),
          text: resp.message,
        });
      }
    });
  };

  const handleClose = () => {
    onClose();
  };
  return (
    <Modal
      modalId="lotteryHistorySlip"
      title={intl.formatMessage({ id: "detail" })}
      dismissBtnRef={dismissBtnRef}
      isVisible={isLotteryOnHoldDetailsVisible}
      modalSize="modal-lg"
      onClose={handleClose}
    >
      <div className="p-3">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <div className="d-flex">
            {/* {Object.entries(LotteryDetailConst).map(([key, value], index) => (
              <div
                key={index}
                className={`me-3 cursor-pointer p-2 rounded px-4 ${
                  value === lotteryDetailMode ? "text-dark fw-bold" : ""
                }`}
                style={{
                  background:
                    value === lotteryDetailMode ? "#ffa500" : "rgba(0,0,0,0.2)",
                }}
                onClick={() => setLotteryDetailMode(value)}
              >
                {intl.formatMessage({ id: value })}
              </div>
            ))} */}
            <div
              className={`me-3 cursor-pointer p-2 rounded px-4 
                  text-dark fw-bold
                `}
              style={{
                background: "#ffa500",
              }}
            >
              {intl.formatMessage({ id: "basic" })}
            </div>
          </div>
        </div>

        {lotteryState.actionsLoading || !lotteryState.lotteryOnHoldDetail ? (
          <Skeleton style={{ height: "350px" }} className="w-100" />
        ) : (
          <div>
            <div>
              {/* {lotteryDetailMode === LotteryDetailConst.BASIC ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: lotteryState.lotteryOnHoldDetail.receipt.replaceAll(
                      "\n",
                      "<br/>"
                    ),
                  }}
                />
              ) : (
                <div className="table-responsive">
                  <table style={{ width: "100%", overflowX: "auto" }}>
                    <thead>
                      <tr className="border-bottom">
                        <th className="p-2">
                          {intl.formatMessage({
                            id: "no",
                          })}
                        </th>
                        <th className="p-2">
                          {intl.formatMessage({
                            id: "date",
                          })}
                        </th>
                        <th className="p-2">
                          {intl.formatMessage({
                            id: "company",
                          })}
                        </th>
                        <th className="p-2">
                          {intl.formatMessage({
                            id: "number",
                          })}
                        </th>
                        <th className="p-2">
                          {intl.formatMessage({
                            id: "big",
                          })}
                        </th>
                        <th className="p-2">
                          {intl.formatMessage({
                            id: "small",
                          })}
                        </th>
                        <th className="p-2">
                          {intl.formatMessage({
                            id: "4a",
                          })}
                        </th>
                        <th className="p-2">
                          {intl.formatMessage({
                            id: "strikeBig",
                          })}
                        </th>
                        <th className="p-2">
                          {intl.formatMessage({
                            id: "strikeSmall",
                          })}
                        </th>
                        <th className="p-2">
                          {intl.formatMessage({
                            id: "strike4a",
                          })}
                        </th>
                        <th className="p-2">
                          {intl.formatMessage({
                            id: "status",
                          })}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {lotteryState &&
                        lotteryState.lotteryOnHoldDetail &&
                        lotteryState.lotteryHistoryDetail.vbossReportDetails &&
                        lotteryState.lotteryHistoryDetail.vbossReportDetails.map(
                          (vBossHistory, index) => (
                            <tr
                              key={index}
                              style={{
                                background:
                                  vBossHistory.status === "void"
                                    ? "#8f0000"
                                    : "transparent",
                              }}
                            >
                              <td className="p-2">{index + 1}</td>
                              <td className="p-2 text-nowrap">
                                {vBossHistory.drawdate}
                              </td>
                              <td className="p-2">
                                <div className="d-flex align-items-center">
                                  <img
                                    src={vBossHistory.iconurl}
                                    style={{ width: "20px" }}
                                    alt={vBossHistory.drawtype}
                                    className="me-2"
                                  />
                                  {vBossHistory.drawtype}
                                </div>
                              </td>
                              <td className="p-2 text-center">
                                {vBossHistory.betnumber}
                              </td>
                              <td className="p-2 text-end">
                                {vBossHistory.betbig}
                              </td>
                              <td className="p-2 text-end">
                                {vBossHistory.betsmall}
                              </td>
                              <td className="p-2 text-end">
                                {vBossHistory.beta}
                              </td>
                              <td className="p-2 text-end">
                                {vBossHistory.strikebig}
                              </td>
                              <td className="p-2 text-end">
                                {vBossHistory.strikesmall}
                              </td>
                              <td className="p-2 text-end">
                                {vBossHistory.strikea}
                              </td>
                              <td className="p-2 text-center">
                                {vBossHistory.status}
                              </td>
                            </tr>
                          )
                        )}
                    </tbody>
                  </table>
                </div>
              )} */}
              <div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: lotteryState.lotteryOnHoldDetail.receipt.replaceAll(
                      "|",
                      "<br/>"
                    ),
                  }}
                />
                <div>
                  {intl.formatMessage({ id: "seasonLeft" }) +
                    " : " +
                    lotteryState.lotteryOnHoldDetail.seasonleft}
                </div>
                <div>
                  {intl.formatMessage({ id: "onHoldBalance" }) +
                    " : " +
                    lotteryState.lotteryOnHoldDetail.onholdbalance}
                </div>
                <div>
                  {intl.formatMessage({ id: "status" }) +
                    " : " +
                    lotteryState.lotteryOnHoldDetail.status}
                </div>
              </div>
            </div>
            {/* <div className="mt-4 d-flex">
              <div
                className="me-2 d-flex align-items-center justify-content-between p-2 rounded flex-fill"
                style={{ background: "rgba(0,0,0,0.2)" }}
              >
                <div>
                  {intl.formatMessage({
                    id: "totalBet",
                  })}
                </div>
                <div>{lotteryState.lotteryOnHoldDetail.totalbet}</div>
              </div>
            </div> */}

            <div className="d-flex align-items-center justify-content-start mt-4">
              {lotteryState.lotteryOnHoldDetail &&
              lotteryState.lotteryOnHoldDetail.status != "void" ? (
                <button
                  type="button"
                  className="btn btn-danger fw-bold me-3 p-2 py-1 text-font3"
                  onClick={() => {
                    setSwalProps({
                      show: true,
                      showCancelButton: true,
                      showConfirmButton: true,
                      confirmButtonText: intl.formatMessage({
                        id: "confirm",
                      }),
                      denyButtonText: intl.formatMessage({
                        id: "cancel",
                      }),
                      icon: "info",
                      title: intl.formatMessage({ id: "alert" }),
                      text: intl.formatMessage({
                        id: "confirmVoidSlip",
                      }),
                      onConfirm: () => {
                        setSwalProps({});
                        voidLottery();
                      },
                    });
                  }}
                >
                  <div className="d-flex align-items-center justify-content-center">
                    <i className="bi bi-x-circle me-2 mb-1" />
                    <div>{intl.formatMessage({ id: "void" })}</div>
                  </div>
                </button>
              ) : (
                <>
                  <button
                    type="button"
                    className="btn btn-danger fw-bold me-3 p-2 py-1 text-font3"
                    onClick={() => {
                      dismissBtnRef.current.click();
                    }}
                  >
                    <div className="d-flex align-items-center justify-content-center">
                      <i className="bi bi-x-circle me-2 mb-1" />
                      <div>{intl.formatMessage({ id: "cancel" })}</div>
                    </div>
                  </button>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};
