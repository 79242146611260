import { useEffect, useRef, useState } from "react";
import { IoMdRefresh } from "react-icons/io";
import { BsFillPersonFill } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import { useAuth } from "../../auth/components/AuthInit";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { toAbsoluteUrl } from "../../../utils/CommonHelper";
import * as actions from "../redux/HomeAction";
import Skeleton from "react-loading-skeleton";
import { LotteryResultListing } from "../../lottery/component/lotteryResult/LotteryResultListing";
import { LotteryBetDialog } from "../../lottery/component/lotteryBet/LotteryBetDialog";
import { LotteryPayout } from "../../lottery/component/lotteryPayout/LotteryPayout";
import { LotteryHistory } from "../../lottery/component/lotteryHistory/LotteryHistory";
import { Wallet } from "../../wallet/component/Wallet";
import { Personal } from "../../profile/component/Profile";
import { Chat } from "../../chat/components/Chat";
import { Report } from "../../report/component/Report";
import { Group } from "../../group/components/Group";
import { Tutorial } from "../../tutorial/component/Tutorial";
import { LotteryOnHold } from "../../lottery/component/lotteryOnHold/LotteryOnHold";
// import { LotteryBetDialog } from "./BetDialog";
// import { LotteryResultListing } from "./Result";

export const Home = ({}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [categoryIndex, setCategoryIndex] = useState(1);
  const { auth } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const homeState = useSelector(({ home }) => home, shallowEqual);
  const [isLoading, setIsLoading] = useState(false);
  const language = localStorage.getItem("locale") || "en";

  const handleWalletClick = () => {
    // navigate("/setting/deposit", { state: { backTo: "/home" } });
  };
  useEffect(() => {
    const category = location.state?.categoryIndex;
    const index = sessionStorage.getItem("categoryIndex");
    if (`${category}` === `${index}`) {
      setCategoryIndex(category);
    } else {
      setCategoryIndex(1);
    }
  }, [location.state]);

  useEffect(() => {
    const queryParams = {
      action: "memberbalance",
    };
    dispatch(actions.callMemberBalance(queryParams));
  }, []);

  const handleRefreshClick = () => {
    setIsLoading(true);
    const queryParams = {
      action: "memberbalance",
    };
    dispatch(actions.callMemberBalance(queryParams));
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };
  const handleCategoryClick = (index) => {
    setCategoryIndex(index);
    navigate("/home", { state: { categoryIndex: index } });
    sessionStorage.setItem("categoryIndex", index);
  };

  return (
    <div>
      <div>
        <img
          src={toAbsoluteUrl("/image/banner/home1.png?1236456")}
          className="w-100 h-100 object-fit-cover"
        />
      </div>
      <div className="d-flex justify-content-center">
        <div
          className=" rounded"
          style={{
            width: "98%",
            border: "1px solid grey",
            backgroundColor: "#181818",
          }}
        >
          {/* <div
            className="d-flex p-1 mx-1"
            style={{
              width: "95%",
              borderBottom: "1px solid grey",
            }}
          >
            <div
              className="d-flex justify-content-center align-items-center"
              style={{
                width: "20px",
                height: "15px",
                backgroundColor: "rgb(245, 190, 9)",
                borderRadius: "50%",
              }}
            >
              <span className=" text-white fw-bold">!</span>
            </div>
            <div className="d-flex justify-content-center w-100 me-1 text-font1"></div>
          </div> */}

          <div className="d-flex justify-content-between align-items-center p-1">
            <div className="d-flex flex-column align-items-start text-font4">
              <div className="d-flex align-items-center ps-1">
                <span className="ps-1">
                  {intl.formatMessage({ id: "welcome" })}&nbsp;
                </span>
                <span style={{ color: "rgb(245, 190, 9)" }}>
                  {auth.username}
                </span>
              </div>{" "}
              {homeState && homeState.memberBalance && (
                <>
                  <div className="align-items-center ps-1">
                    <span className="ps-1">
                      <>
                        {homeState.memberBalance.currencycode}&nbsp;{":"}&nbsp;
                        {isLoading ? "-" : homeState.memberBalance.balance}
                      </>
                    </span>
                    {!isLoading && (
                      <i
                        className="bi bi-arrow-clockwise mx-2"
                        onClick={() => handleRefreshClick()}
                      />
                    )}
                  </div>
                  <div className="align-items-center ps-1">
                    <span
                      className="ps-1"
                      style={{ fontSize: "12px", fontStyle: "italic" }}
                    >
                      <>
                        {intl.formatMessage({ id: "nextComing" })}&nbsp;{":"}
                        &nbsp;
                        {homeState.memberBalance.nextcomingdate +
                          "(" +
                          homeState.memberBalance.nextcomingday +
                          ")"}
                      </>
                    </span>
                  </div>
                </>
              )}
            </div>
            <div onClick={() => handleCategoryClick(7)}>
              <div className="d-flex justify-content-center px-3">
                <div>
                  {/* <i
                    className="bi bi-wallet2"
                    style={{
                      fontSize: "20px",
                      color: categoryIndex === 7 ? "#f5be09" : "",
                    }}
                  /> */}
                  {language === "zh-cn" ? (
                    <img
                      src={
                        categoryIndex === 7
                          ? toAbsoluteUrl(`image/wallet/zh/wallet2.png`)
                          : toAbsoluteUrl(`image/wallet/zh/wallet1.png`)
                      }
                      alt="wallet"
                      className="my-1"
                      style={{
                        width: "50px",
                        height: "50px",
                      }}
                    />
                  ) : (
                    <img
                      src={
                        categoryIndex === 7
                          ? toAbsoluteUrl(`image/wallet/wallet2.png`)
                          : toAbsoluteUrl(`image/wallet/wallet1.png`)
                      }
                      alt="wallet"
                      className="my-1"
                      style={{
                        width: "50px",
                        height: "50px",
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex mx-1 mt-1">
        {language === "zh-cn" ? (
          <div className="col-1.5">
            <div>
              <div onClick={() => handleCategoryClick(0)}>
                <img
                  src={
                    categoryIndex === 0
                      ? toAbsoluteUrl(`/image/homePage/navbar/zh/result1.png`)
                      : toAbsoluteUrl(`/image/homePage/navbar/zh/result.png`)
                  }
                  alt="home"
                  className="w-100 h-100 object-fit-cover my-1"
                />
              </div>
              <div onClick={() => handleCategoryClick(1)}>
                <img
                  src={
                    categoryIndex === 1
                      ? toAbsoluteUrl(`/image/homePage/navbar/zh/bet1.png`)
                      : toAbsoluteUrl(`/image/homePage/navbar/zh/bet.png`)
                  }
                  alt="betnow"
                  className="w-100 h-100 object-fit-cover my-1"
                />
              </div>
              <div onClick={() => handleCategoryClick(2)}>
                <img
                  src={
                    categoryIndex === 2
                      ? toAbsoluteUrl(`/image/homePage/navbar/zh/slip1.png`)
                      : toAbsoluteUrl(`/image/homePage/navbar/zh/slip.png`)
                  }
                  alt="slip"
                  className="w-100 h-100 object-fit-cover my-1"
                />
              </div>
              <div onClick={() => handleCategoryClick(10)}>
                <img
                  src={
                    categoryIndex === 10
                      ? toAbsoluteUrl(`/image/homePage/navbar/zh/incoming1.png`)
                      : toAbsoluteUrl(`/image/homePage/navbar/zh/incoming.png`)
                  }
                  alt="incoming"
                  className="w-100 h-100 object-fit-cover my-1"
                />
              </div>
              <div onClick={() => handleCategoryClick(3)}>
                <img
                  src={
                    categoryIndex === 3
                      ? toAbsoluteUrl(`/image/homePage/navbar/zh/prize1.png`)
                      : toAbsoluteUrl(`/image/homePage/navbar/zh/prize.png`)
                  }
                  alt="prize"
                  className="w-100 h-100 object-fit-cover my-1"
                />
              </div>
              <div onClick={() => handleCategoryClick(4)}>
                <img
                  src={
                    categoryIndex === 4
                      ? toAbsoluteUrl(`/image/homePage/navbar/zh/report1.png`)
                      : toAbsoluteUrl(`/image/homePage/navbar/zh/report.png`)
                  }
                  alt="report"
                  className="w-100 h-100 object-fit-cover my-1"
                />
              </div>
              {/* {auth.plancode === "plana" && (
                <div onClick={() => handleCategoryClick(8)}>
                  <img
                    src={
                      categoryIndex === 8
                        ? toAbsoluteUrl(`/image/homePage/navbar/zh/group1.png`)
                        : toAbsoluteUrl(`/image/homePage/navbar/zh/group.png`)
                    }
                    alt="profile"
                    className="w-100 h-100 object-fit-cover my-1"
                  />
                </div>
              )} */}
              <div onClick={() => handleCategoryClick(8)}>
                <img
                  src={
                    categoryIndex === 8
                      ? toAbsoluteUrl(`/image/homePage/navbar/zh/group1.png`)
                      : toAbsoluteUrl(`/image/homePage/navbar/zh/group.png`)
                  }
                  alt="profile"
                  className="w-100 h-100 object-fit-cover my-1"
                />
              </div>
              <div onClick={() => handleCategoryClick(5)}>
                <img
                  src={
                    categoryIndex === 5
                      ? toAbsoluteUrl(`/image/homePage/navbar/zh/chat1.png`)
                      : toAbsoluteUrl(`/image/homePage/navbar/zh/chat.png`)
                  }
                  alt="chat"
                  className="w-100 h-100 object-fit-cover my-1"
                />
              </div>
              <div onClick={() => handleCategoryClick(6)}>
                <img
                  src={
                    categoryIndex === 6
                      ? toAbsoluteUrl(`/image/homePage/navbar/zh/profile1.png`)
                      : toAbsoluteUrl(`/image/homePage/navbar/zh/profile.png`)
                  }
                  alt="profile"
                  className="w-100 h-100 object-fit-cover my-1"
                />
              </div>
              <div onClick={() => handleCategoryClick(9)}>
                <img
                  src={
                    categoryIndex === 9
                      ? toAbsoluteUrl(`/image/homePage/navbar/zh/tutorial1.png`)
                      : toAbsoluteUrl(`/image/homePage/navbar/zh/tutorial.png`)
                  }
                  alt="chat"
                  className="w-100 h-100 object-fit-cover my-1"
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="col-1.5">
            <div>
              <div onClick={() => handleCategoryClick(0)}>
                <img
                  src={
                    categoryIndex === 0
                      ? toAbsoluteUrl(`/image/homePage/navbar/result1.png`)
                      : toAbsoluteUrl(`/image/homePage/navbar/result.png`)
                  }
                  alt="home"
                  className="w-100 h-100 object-fit-cover my-1"
                />
              </div>
              <div onClick={() => handleCategoryClick(1)}>
                <img
                  src={
                    categoryIndex === 1
                      ? toAbsoluteUrl(`/image/homePage/navbar/bet1.png`)
                      : toAbsoluteUrl(`/image/homePage/navbar/bet.png`)
                  }
                  alt="betnow"
                  className="w-100 h-100 object-fit-cover my-1"
                />
              </div>
              <div onClick={() => handleCategoryClick(2)}>
                <img
                  src={
                    categoryIndex === 2
                      ? toAbsoluteUrl(`/image/homePage/navbar/slip1.png`)
                      : toAbsoluteUrl(`/image/homePage/navbar/slip.png`)
                  }
                  alt="slip"
                  className="w-100 h-100 object-fit-cover my-1"
                />
              </div>
              {/* <div onClick={() => handleCategoryClick(10)}>
                <img
                  src={
                    categoryIndex === 10
                      ? toAbsoluteUrl(`/image/homePage/navbar/incoming1.png`)
                      : toAbsoluteUrl(`/image/homePage/navbar/incoming.png`)
                  }
                  alt="incoming"
                  className="w-100 h-100 object-fit-cover my-1"
                />
              </div> */}
              <div onClick={() => handleCategoryClick(3)}>
                <img
                  src={
                    categoryIndex === 3
                      ? toAbsoluteUrl(`/image/homePage/navbar/prize1.png`)
                      : toAbsoluteUrl(`/image/homePage/navbar/prize.png`)
                  }
                  alt="prize"
                  className="w-100 h-100 object-fit-cover my-1"
                />
              </div>
              <div onClick={() => handleCategoryClick(4)}>
                <img
                  src={
                    categoryIndex === 4
                      ? toAbsoluteUrl(`/image/homePage/navbar/report1.png`)
                      : toAbsoluteUrl(`/image/homePage/navbar/report.png`)
                  }
                  alt="report"
                  className="w-100 h-100 object-fit-cover my-1"
                />
              </div>
              {/* {auth.plancode === "plana" && (
                <div onClick={() => handleCategoryClick(8)}>
                  <img
                    src={
                      categoryIndex === 8
                        ? toAbsoluteUrl(`/image/homePage/navbar/group1.png`)
                        : toAbsoluteUrl(`/image/homePage/navbar/group.png`)
                    }
                    alt="profile"
                    className="w-100 h-100 object-fit-cover my-1"
                  />
                </div>
              )} */}
              <div onClick={() => handleCategoryClick(8)}>
                <img
                  src={
                    categoryIndex === 8
                      ? toAbsoluteUrl(`/image/homePage/navbar/group1.png`)
                      : toAbsoluteUrl(`/image/homePage/navbar/group.png`)
                  }
                  alt="profile"
                  className="w-100 h-100 object-fit-cover my-1"
                />
              </div>
              <div onClick={() => handleCategoryClick(5)}>
                <img
                  src={
                    categoryIndex === 5
                      ? toAbsoluteUrl(`/image/homePage/navbar/chat1.png`)
                      : toAbsoluteUrl(`/image/homePage/navbar/chat.png`)
                  }
                  alt="chat"
                  className="w-100 h-100 object-fit-cover my-1"
                />
              </div>
              <div onClick={() => handleCategoryClick(6)}>
                <img
                  src={
                    categoryIndex === 6
                      ? toAbsoluteUrl(`/image/homePage/navbar/profile1.png`)
                      : toAbsoluteUrl(`/image/homePage/navbar/profile.png`)
                  }
                  alt="profile"
                  className="w-100 h-100 object-fit-cover my-1"
                />
              </div>
              <div onClick={() => handleCategoryClick(9)}>
                <img
                  src={
                    categoryIndex === 9
                      ? toAbsoluteUrl(`/image/homePage/navbar/tutorial1.png`)
                      : toAbsoluteUrl(`/image/homePage/navbar/tutorial.png`)
                  }
                  alt="tutorial"
                  className="w-100 h-100 object-fit-cover my-1"
                />
              </div>
            </div>
          </div>
        )}
        <div className="col-10 mx-1">
          {categoryIndex === 0 ? (
            <>
              <LotteryResultListing />
              {/* <Personal /> */}
            </>
          ) : categoryIndex === 1 ? (
            <>
              <LotteryBetDialog />
            </>
          ) : categoryIndex === 2 ? (
            <>
              <LotteryHistory />
            </>
          ) : categoryIndex === 3 ? (
            <>
              <LotteryPayout />
            </>
          ) : categoryIndex === 4 ? (
            <>
              <Report />
            </>
          ) : categoryIndex === 5 ? (
            <>
              <Chat />
            </>
          ) : categoryIndex === 6 ? (
            <>
              <Personal />
            </>
          ) : categoryIndex === 7 ? (
            <>
              <Wallet />
            </>
          ) : categoryIndex === 8 ? (
            <>
              <Group />
            </>
          ) : categoryIndex === 9 ? (
            <>
              <Tutorial />
            </>
          ) : categoryIndex === 10 ? (
            <>
              <LotteryOnHold />
            </>
          ) : (
            <>
              <LotteryPayout />
            </>
          )}
        </div>
      </div>
    </div>
  );
};
