import { combineReducers } from "redux";
import { homeSlice } from "../modules/home/redux/HomeSlice";
import { masterLayoutSlice } from "../modules/common/redux/MasterLayoutSlice";
import { lotterySlice } from "../modules/lottery/redux/LotterySlice";
import { profileSlice } from "../modules/profile/redux/ProfileSlice";
import { walletSlice } from "../modules/wallet/redux/WalletSlice";
import { reportSlice } from "../modules/report/redux/ReportSlice";
import { groupSlice } from "../modules/group/redux/GroupSlice";

export const rootReducer = combineReducers({
  // get from slice file
  masterLayout: masterLayoutSlice.reducer,
  home: homeSlice.reducer,
  lottery: lotterySlice.reducer,
  profile: profileSlice.reducer,
  wallet: walletSlice.reducer,
  report: reportSlice.reducer,
  group: groupSlice.reducer,
});
