import { LanguageLocale } from "../constants/LanguageLocale";

import en from "date-fns/locale/en-US";
import zh from "date-fns/locale/zh-CN";

export function getDateLocale(value) {
  if (value === LanguageLocale.ZH) {
    return zh;
  } else {
    return en;
  }
}
