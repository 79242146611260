import { createSlice } from "@reduxjs/toolkit";
import { ReduxCallTypesConst } from "../../../constants/ReduxCallTypesConst";

const walletState = {
  topupManualUI: null,
  topupManualRequest: null,
  topupManualCancel: null,
  topupGatewayUI: null,
  topupGatewayRequest: null,
  bankCardList: null,
  addBankCardList: null,
  removeBankCardList: null,
  withdrawUIList: null,
  withdrawRequestList: null,
  withdrawListCancel: null,
  topupWithdrawPendingList: null,
  topupWithdrawDetailsList: null,
  error: null,
  listLoading: false,
  actionsLoading: false,
  searchLoading: false,
  isRefresh: false,
};

export const walletSlice = createSlice({
  name: "wallet",
  initialState: walletState,
  reducers: {
    catchError: (state, action) => {
      state.error = action.payload.error;

      if (action.payload.callType === ReduxCallTypesConst.LIST) {
        state.listLoading = false;
      } else if (action.payload.callType === ReduxCallTypesConst.ACTION) {
        state.actionsLoading = false;
      } else if (action.payload.callType === ReduxCallTypesConst.DETAIL) {
        state.searchLoading = false;
      }
    },
    throwError: (state, action) => {
      state.error = action.payload.data.error;
    },
    startCall: (state, action) => {
      state.isRefresh = false;
      state.error = null;

      if (action.payload.callType === ReduxCallTypesConst.LIST) {
        state.listLoading = true;
      } else if (action.payload.callType === ReduxCallTypesConst.ACTION) {
        state.actionsLoading = true;
      } else if (action.payload.callType === ReduxCallTypesConst.DETAIL) {
        state.searchLoading = true;
      }
    },
    clearError: (state) => {
      state.error = null;
    },
    topupManualUIFetched: (state, action) => {
      state.listLoading = false;
      state.topupManualUI = action.payload.data ?? {};
    },
    topupManualRequestFetched: (state, action) => {
      state.listLoading = false;
      state.topupManualRequest = action.payload.data ?? {};
    },
    topupManualCancelFetched: (state, action) => {
      state.listLoading = false;
      state.topupManualCancel = action.payload.data ?? {};
    },
    topupGatewayUIFetched: (state, action) => {
      state.listLoading = false;
      state.topupGatewayUI = action.payload.data ?? {};
    },
    topupGatewayRequestFetched: (state, action) => {
      state.listLoading = false;
      state.topupGatewayRequest = action.payload.data ?? {};
    },
    bankCardListFetched: (state, action) => {
      state.listLoading = false;
      state.bankCardList = action.payload.data ?? {};
    },
    addBankCardListFetched: (state, action) => {
      state.listLoading = false;
      state.addBankCardList = action.payload.data ?? {};
    },
    removeBankCardListFetched: (state, action) => {
      state.listLoading = false;
      state.removeBankCardList = action.payload.data ?? {};
    },
    withdrawUIListFetched: (state, action) => {
      state.listLoading = false;
      state.withdrawUIList = action.payload.data ?? {};
    },
    withdrawRequestListFetched: (state, action) => {
      state.listLoading = false;
      state.withdrawRequestList = action.payload.data ?? {};
    },
    withdrawListCancelFetched: (state, action) => {
      state.listLoading = false;
      state.withdrawListCancel = action.payload.data ?? {};
    },
    topupWithdrawPendingListFetched: (state, action) => {
      state.listLoading = false;
      state.topupWithdrawPendingList = action.payload.data ?? {};
    },
    topupWithdrawDetailsListFetched: (state, action) => {
      state.listLoading = false;
      state.topupWithdrawDetailsList = action.payload.data ?? {};
    },
  },
});
