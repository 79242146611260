import { useIntl } from "react-intl";
import { useRef } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../redux/WalletAction";
import { ApiStatusTypeEnum } from "../../../../enumeration/ApiStatusTypeEnum";
import { Modal } from "../../../common/components/Modal1";
import { callMemberBalance } from "../../../home/redux/HomeAction";

export const EditStatusDialog = ({
  setSwalProps,
  reportType,
  index,
  isVisible,
  onClose,
}) => {
  const intl = useIntl();
  const formikRef = useRef();
  const dispatch = useDispatch();
  const dismissBtnRef = useRef();
  const walletState = useSelector(({ wallet }) => wallet, shallowEqual);

  const handleClick = () => {
    if (reportType === "withdraw") {
      const queryParams = {
        action: "withdrawcancel",
        selitemid: `${index}`,
      };
      dispatch(actions.withdrawListCancel(queryParams)).then((resp) => {
        resp = resp.data;

        if (parseInt(resp.code) === ApiStatusTypeEnum.SUCCESS) {
          const queryParams = {
            action: "memberbalance",
          };
          dispatch(callMemberBalance(queryParams));
          setSwalProps({
            show: true,
            icon: "success",
            title: intl.formatMessage({ id: "success" }),
            text: intl.formatMessage({ id: "withdrawCancelSuccess" }),
            onConfirm: () => {
              dismissBtnRef.current.click();
              onClose();
            },
            onClose: () => {
              onClose();
            },
          });
        } else {
          setSwalProps({
            show: true,
            icon: "error",
            title: intl.formatMessage({ id: "error" }),
            text: resp.message,
          });
        }
      });
    } else if (reportType === "manualtopup") {
      const queryParams = {
        action: "manualtopupcancel",
        selitemid: `${index}`,
      };
      dispatch(actions.topupManualCancel(queryParams)).then((resp) => {
        resp = resp.data;

        if (parseInt(resp.code) === ApiStatusTypeEnum.SUCCESS) {
          const queryParams = {
            action: "memberbalance",
          };
          dispatch(callMemberBalance(queryParams));
          setSwalProps({
            show: true,
            icon: "success",
            title: intl.formatMessage({ id: "success" }),
            text: intl.formatMessage({ id: "manualTopupCancelSuccess" }),
            onConfirm: () => {
              dismissBtnRef.current.click();
              onClose();
            },
            onClose: () => {
              onClose();
            },
          });
        } else {
          setSwalProps({
            show: true,
            icon: "error",
            title: intl.formatMessage({ id: "error" }),
            text: resp.message,
          });
        }
      });
    }
  };

  const handleCancel = () => {
    if (dismissBtnRef.current) {
      dismissBtnRef.current.click();
    }
  };

  return (
    <Modal
      modalId="editAgentModal"
      title={intl.formatMessage({ id: "editStatus" })}
      dismissBtnRef={dismissBtnRef}
      isVisible={isVisible}
      onClose={onClose}
    >
      <div className="p-3 custom-border-bottom">
        {intl.formatMessage({ id: "confirmEditToCancel" })}
      </div>
      <div className="p-3 d-flex justify-content-end align-items-center">
        <button
          className="btn btn-success btn-sm mx-1"
          type="submit"
          onClick={handleClick}
        >
          {intl.formatMessage({ id: "confirm" })}
        </button>
        <button
          className="btn btn-danger btn-sm"
          type="button"
          onClick={handleCancel}
        >
          {intl.formatMessage({ id: "cancel" })}
        </button>
      </div>
    </Modal>
  );
};
