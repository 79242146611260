import { useEffect } from "react";
import * as actions from "../../redux/LotteryAction";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { toAbsoluteUrl } from "../../../../utils/CommonHelper";
import { useIntl } from "react-intl";
import { useState } from "react";
import { useRef } from "react";

export const LotteryResultListing = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const countDown = 30;
  const timerRef = useRef(countDown);
  const [timer, setTimer] = useState(countDown);

  useEffect(() => {
    dispatch(actions.getLotteryResultUI());
    const fetchLotteryInterval = setInterval(() => {
      if (timerRef.current > 0) {
        timerRef.current--;
        setTimer(timerRef.current);
      } else {
        timerRef.current = countDown;
        setTimer(timerRef.current);
        dispatch(actions.getLotteryResultUI());
      }
    }, 1000);

    return () => clearInterval(fetchLotteryInterval);
  }, []);

  const lotteryState = useSelector(({ lottery }) => lottery, shallowEqual);

  const LotteryCard = ({ lotteryResult }) => (
    <div className="text-font3 mb-2">
      <div
        className={`bg-${lotteryResult.lotterycode} rounded overflow-hidden`}
        style={{ border: "1px solid white" }}
      >
        <div
          className={`d-flex p-2 justify-content-between align-items-center custom-border-bottom lottery-head-${lotteryResult.lotterycode}`}
          style={{ border: "1px solid white" }}
        >
          <div className="fw-bold">
            <div className="mb-1">{lotteryResult.lotteryname}</div>
            <div>{lotteryResult.drawdate}</div>
          </div>
          <img
            src={toAbsoluteUrl(
              `/image/lotteryCompany/${lotteryResult.lotterycode}.png`
            )}
            alt="magnum"
            style={{ height: "50px" }}
          />
        </div>
        <div className="fw-bold text-font2">
          <div
            className="d-flex p-3 justify-content-center align-items-center"
            style={{ borderBottom: "1px solid white" }}
          >
            <div className="text-end pe-1 text-font3">
              1ST {intl.formatMessage({ id: "prize" }).toUpperCase()}
            </div>
            <div className="text-start ps-1">{lotteryResult.firstprize}</div>
          </div>
          <div
            className="d-flex p-3 justify-content-center align-items-center"
            style={{ borderBottom: "1px solid white" }}
          >
            <div className="text-end pe-1 text-font3">
              2ND {intl.formatMessage({ id: "prize" }).toUpperCase()}
            </div>
            <div className="text-start ps-1">{lotteryResult.secondprize}</div>
          </div>
          <div
            className="d-flex p-3 justify-content-center align-items-center"
            style={{ borderBottom: "1px solid white" }}
          >
            <div className="text-end pe-1 text-font3">
              3RD {intl.formatMessage({ id: "prize" }).toUpperCase()}
            </div>
            <div className="text-start ps-1">{lotteryResult.thirdprize}</div>
          </div>
          <div style={{ borderBottom: "1px solid white" }}>
            <div className="text-font3 text-center py-2">
              {intl.formatMessage({ id: "special" }).toUpperCase()}
            </div>
            <div className="row px-2">
              {Object.entries(lotteryResult)
                .filter(([key, value]) => key.includes("special"))
                .map(([key, value]) => (
                  <div
                    className="mb-2 text-center"
                    key={key}
                    style={{ width: "20%" }}
                  >
                    {value}
                  </div>
                ))}
            </div>
          </div>
          <div>
            <div className=" text-font3 text-center py-2">
              {intl.formatMessage({ id: "consolation" }).toUpperCase()}
            </div>
            <div className="row px-2">
              {Object.entries(lotteryResult)
                .filter(([key, value]) => key.includes("consolation"))
                .map(([key, value]) => (
                  <div
                    className="mb-2 text-center"
                    key={key}
                    style={{ width: "20%" }}
                  >
                    {value}
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div
      style={{
        overflowY: "auto",
        overflowX: "hidden",
        maxHeight: "74vh",
        width: "100%",
      }}
    >
      <div className="row justify-content-center">
        {lotteryState &&
          lotteryState.lotteryResultUI &&
          lotteryState.lotteryResultUI.lotteryResultUI && (
            <>
              {lotteryState.lotteryResultUI.lotteryResultUI.map(
                (lotteryResult, index) => (
                  <LotteryCard key={index} lotteryResult={lotteryResult} />
                )
              )}
            </>
          )}
      </div>
      <div
        className="mt-3 d-flex align-items-center justify-content-end text-muted pe-2"
        style={{ height: "30px" }}
      >
        {/* <div className="text-white">
          {intl.formatMessage({ id: "refreshIn" })} {timer}s
        </div> */}
      </div>
    </div>
  );
};
