import { useEffect, useRef, useState } from "react";
import { useAuth } from "../../../../auth/components/AuthInit";
import { BankAccountDialog } from "../bankAccountDialog/BankAccountListDialog";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../redux/WalletAction";
import {
  CheckIsNull,
  CopyToClipboard,
} from "../../../../../utils/CommonHelper";
import { ApiStatusTypeEnum } from "../../../../../enumeration/ApiStatusTypeEnum";
import { useIntl } from "react-intl";
import { DepositAtmForm } from "./DepositAtmForm";
import Skeleton from "react-loading-skeleton";

export const DepositAtmDialog = ({ setSwalProps, dismissBtnRef }) => {
  const dispatch = useDispatch();
  const imageName = Date.now();
  const formikRef = useRef();
  const receiptRef = useRef();
  const { auth } = useAuth();
  const intl = useIntl();
  const [selectedBank, setSelectedBank] = useState(0);
  const [isShow, setIsShow] = useState(false);

  const walletState = useSelector(({ wallet }) => wallet, shallowEqual);

  useEffect(() => {
    const queryParams = {
      action: "topupmanualui",
    };
    dispatch(actions.topupManualUI(queryParams));
  }, []);

  const saveValues = (queryParams) => {
    const newQueryParams = {
      memberid: auth.memberid,
      loginid: auth.loginid,
      action: "topupmanualrequest",
      bankitemid: walletState.topupManualUI.topupManualUIs[selectedBank].itemid,
      topupamount: queryParams.topupamount,
      imagename: `${imageName}.${queryParams.receipt.name
        .split(".")
        .slice(-1)}`,
    };

    const formData = new FormData();
    formData.append("memberid", imageName);
    formData.append("receiptPath", queryParams.receipt);
    formData.append("jsonData", JSON.stringify(newQueryParams));
    dispatch(actions.topupManualRequest(formData)).then((resp) => {
      resp = resp.data;

      if (parseInt(resp.code) === ApiStatusTypeEnum.SUCCESS) {
        setSwalProps({
          show: true,
          icon: "success",
          title: intl.formatMessage({ id: "success" }),
          text: intl.formatMessage({ id: "depositRequestSendSuccessfully" }),
          onResolve: () => {
            setSwalProps({});
            formikRef.current.resetForm();
            if (dismissBtnRef) {
              dismissBtnRef.current.click();
            }
            if (receiptRef.current) {
              receiptRef.current.value = null;
            }
          },
        });
      } else {
        setSwalProps({
          show: true,
          icon: "error",
          title: intl.formatMessage({ id: "error" }),
          text: resp.message,
        });
      }
    });
  };

  return (
    <>
      {walletState &&
      walletState.topupManualUI &&
      walletState.topupManualUI.topupManualUIs &&
      walletState.topupManualUI.topupManualUIs.length > 0 ? (
        <div>
          <BankAccountDialog
            selectedBank={selectedBank}
            setSelectedBank={setSelectedBank}
            bankList={walletState.topupManualUI.topupManualUIs}
            isShow={isShow}
            setIsShow={setIsShow}
          />

          <div>{intl.formatMessage({ id: "depositAccount" })}</div>
          <div
            className="rounded custom-border p-3 d-flex justify-content-between align-items-center cursor-pointer"
            style={{ background: "rgba(33, 35, 41)" }}
            onClick={() => setIsShow(true)}
          >
            <div>
              <div className="small ">
                {intl.formatMessage({ id: "bankName" })}
              </div>
              <div className="mb-2  fw-bold">
                {
                  walletState.topupManualUI.topupManualUIs[selectedBank]
                    .bankname
                }
              </div>
              <div className="small ">
                {intl.formatMessage({ id: "accountName" })}
              </div>
              <div className="mb-2 d-flex fw-bold align-items-center">
                <div className="me-3">
                  {
                    walletState.topupManualUI.topupManualUIs[selectedBank]
                      .accountname
                  }
                </div>
                <div>
                  <i
                    className="bi bi-copy cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (sessionStorage.getItem("platform") === "mobile") {
                        console.log(
                          `clipboard: ${walletState.topupManualUI.topupManualUIs[selectedBank].accountname}`
                        );
                        CopyToClipboard(
                          walletState.topupManualUI.topupManualUIs[selectedBank]
                            .accountname,
                          setSwalProps,
                          intl
                        );
                      } else {
                        CopyToClipboard(
                          walletState.topupManualUI.topupManualUIs[selectedBank]
                            .accountname,
                          setSwalProps,
                          intl
                        );
                      }
                    }}
                  />
                </div>
              </div>
              <div className=" small ">
                {intl.formatMessage({ id: "accountNumber" })}
              </div>
              <div className="fw-bold d-flex align-items-center">
                <div className="me-3">
                  {
                    walletState.topupManualUI.topupManualUIs[selectedBank]
                      .accountnum
                  }
                </div>
                <div>
                  <i
                    className="bi bi-copy cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (sessionStorage.getItem("platform") === "mobile") {
                        console.log(
                          `clipboard: ${walletState.topupManualUI.topupManualUIs[selectedBank].accountnum}`
                        );
                        CopyToClipboard(
                          walletState.topupManualUI.topupManualUIs[selectedBank]
                            .accountnum,
                          setSwalProps,
                          intl
                        );
                      } else {
                        CopyToClipboard(
                          walletState.topupManualUI.topupManualUIs[selectedBank]
                            .accountnum,
                          setSwalProps,
                          intl
                        );
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <div>
              <i className="bi bi-chevron-right fs-1 me-4" />
            </div>
          </div>
          <div className="my-4">
            <DepositAtmForm
              formikRef={formikRef}
              receiptRef={receiptRef}
              saveValues={saveValues}
            />
          </div>

          <button
            type="button"
            disabled={walletState.actionsLoading}
            style={{ color: "black", backgroundColor: "#f5be09" }}
            className="btn btn-prim fw-bold px-3 w-100 mb-4 mt-2"
            onClick={() => formikRef.current.submitForm()}
          >
            <div className="d-flex align-items-center justify-content-center">
              {walletState.actionsLoading && (
                <div
                  className="spinner-border text-dark"
                  role="status"
                  style={{ scale: "0.75" }}
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
              {intl.formatMessage({ id: "submit" })}
            </div>
          </button>
        </div>
      ) : (
        <>
          <Skeleton className="w-100" height="500px" />
        </>
      )}
    </>
  );
};
