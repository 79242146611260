import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { SkeletonTheme } from "react-loading-skeleton";
import "react-phone-input-2/lib/style.css";
import { IntlProvider } from "react-intl";
import "./App.css";
import { Provider } from "react-redux";
import store from "./setup/Store";
import { AuthProvider } from "./modules/auth/components/AuthInit";
import { AppRoutes } from "./routing/AppRoutes";
import { LOCALES } from "./i18n/locales";
import { messages } from "./i18n/messages";

function App() {
  function getInitialLocal() {
    const savedLocale = localStorage.getItem("locale");
    return savedLocale || LOCALES.ENGLISH;
  }

  return (
    <Provider store={store}>
      <IntlProvider
        messages={messages[getInitialLocal()]}
        locale={getInitialLocal()}
        defaultLocale={LOCALES.ENGLISH}
      >
        <AuthProvider>
          <SkeletonTheme baseColor="#202020" highlightColor="#444">
            <AppRoutes />
          </SkeletonTheme>
        </AuthProvider>
      </IntlProvider>
    </Provider>
  );
}

export default App;
