/* eslint-disable jsx-a11y/anchor-is-valid */
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { LoginRegisterDialog } from "../../modules/auth/components/LoginRegisterDialog";
import { LoginTab } from "../../modules/auth/enum/LoginTab";

const AuthWrapper = () => (
  <Routes>
    <Route element={<Outlet />}>
      <Route path="*" element={<Navigate to="/login" />} />
      <Route
        path="/login"
        element={<LoginRegisterDialog initialTab={LoginTab.LOGIN} />}
      />
      <Route
        path="/credential/:credential"
        element={<LoginRegisterDialog initialTab={LoginTab.LOGIN} />}
      />
      <Route
        path="/credential/:credential/:credential/:credential"
        element={<LoginRegisterDialog initialTab={LoginTab.LOGIN} />}
      />
      <Route
        path="/:credential"
        element={<LoginRegisterDialog initialTab={LoginTab.LOGIN} />}
      />
      <Route
        path="/register"
        element={<LoginRegisterDialog initialTab={LoginTab.REGISTER_OPTION} />}
      />
      <Route
        path="/register/:refferalCode"
        element={<LoginRegisterDialog initialTab={LoginTab.REGISTER_OPTION} />}
      />
      <Route index element={<LoginRegisterDialog />} />
    </Route>

    <Route element={<Outlet />}></Route>
  </Routes>
);

export { AuthWrapper };
