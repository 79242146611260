import { useState } from "react";
import { useIntl } from "react-intl";
import { WithdrawNavTitle } from "../../../../constants/WithdrawNavConst";
import { WithdrawDialog } from "./withdrawDialog/WithdrawDialog";
import { FiArrowLeft } from "react-icons/fi";
import { Link, useLocation } from "react-router-dom";

export const Withdraw = () => {
  const [selectedNav, setSelectedNav] = useState(WithdrawNavTitle.WITHDRAW);
  const intl = useIntl();
  const location = useLocation();

  return (
    <div
      style={{
        width: "100%",
        margin: "5px auto",
        backgroundColor: "#1c1e23",
        borderRadius: "5px",
      }}
    >
      {selectedNav === WithdrawNavTitle.WITHDRAW ? <WithdrawDialog /> : <></>}
    </div>
  );
};
