import axios from "axios";
import { authGet1, authPost } from "../../common/redux/ApiClient";

const baseUrl = "http://34.87.51.38/w188_api";

export function fetchLotteryResultUI() {
  return authGet1(
    `http://34.87.51.38/ok99memberApi/api/Member5/LotteryResultUI`,
    {}
  );
}
export function fetchLotteryPrizeUI(queryParams) {
  return authPost(`${baseUrl}/Member2.ashx`, queryParams);
}
export function fetchLotteryUI(queryParams) {
  return authPost(`${baseUrl}/Member4.ashx`, queryParams);
}
