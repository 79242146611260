import { Field, Form, Formik } from "formik";
import { useIntl } from "react-intl";
import {
  ErrorContainer,
  Input,
} from "../../../../common/components/control/InputField";
import { depositSchema } from "../../../yupSchema/depositSchema";
import { CheckIsNull } from "../../../../../utils/CommonHelper";

export const DepositGatewayForm = ({ formikRef, saveValues }) => {
  const intl = useIntl();

  return (
    <Formik
      initialValues={{
        amount: "",
      }}
      innerRef={formikRef}
      validationSchema={depositSchema(intl)}
      enableReinitialize={true}
      onSubmit={(values, formikHelpers) => {
        const queryParams = {
          amount: values.amount,
        };

        saveValues(queryParams);
      }}
    >
      {({
        values,
        handleSubmit,
        setFieldValue,
        touched,
        errors,
        setFieldError,
      }) => (
        <Form>
          <label
            className="mb-2 text-input-label text-font3"
            style={{ color: "white" }}
          >
            {intl.formatMessage({ id: "amount" })}
          </label>
          <div className="mb-2">
            <Field
              className="text-font2"
              name="amount"
              component={Input}
              placeholder={intl.formatMessage({ id: "amount" })}
              autoComplete="off"
              type="number"
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};
