import { useState } from "react";
import { useIntl } from "react-intl";
import {
  HistoryStatusConst,
  historyDurationList,
} from "../../../../constants/HistoryConst";
import * as actions from "../../redux/WalletAction";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import SweetAlert2 from "react-sweetalert2";
import { ApiStatusTypeEnum } from "../../../../enumeration/ApiStatusTypeEnum";
import { useLocation, useNavigate } from "react-router-dom";
import { HistoryDetails } from "./HistoryDetail";

export const History = () => {
  const intl = useIntl();
  const [swalProps, setSwalProps] = useState({});
  const dispatch = useDispatch();
  const location = useLocation();
  const [dayType, setDayType] = useState(location.state?.dayType || "1");
  const [viewType, setViewType] = useState("all");
  const [historyDetailsVisible, setHistoryDetailsVisible] = useState(false);
  const [index, setIndex] = useState("");
  const [reportType, setReportType] = useState("");
  const [reportTypeDesc, setReportTypeDesc] = useState("");
  const walletState = useSelector(({ wallet }) => wallet, shallowEqual);

  useEffect(() => {
    const dayType = location.state?.dayType || "1";
    setDayType(dayType);
    const queryParams = {
      action: "topupwithdrawpendinglist",
      days: dayType,
      viewtype: viewType,
    };
    dispatch(actions.topupWithdrawPendingList(queryParams));
  }, []);

  const handleDateChange = (value) => {
    setDayType(value);
    const queryParams = {
      action: "topupwithdrawpendinglist",
      days: value,
      viewtype: viewType,
    };
    dispatch(actions.topupWithdrawPendingList(queryParams));
  };

  const handleViewTypeChange = (value) => {
    setViewType(value);
    const queryParams = {
      action: "topupwithdrawpendinglist",
      days: dayType,
      viewtype: value,
    };
    dispatch(actions.topupWithdrawPendingList(queryParams));
  };

  return (
    <div style={{ width: "100%" }}>
      <SweetAlert2
        customClass="custom-swal"
        onResolve={() => {
          setSwalProps({});
        }}
        {...swalProps}
      />
      <HistoryDetails
        isHistoryDetailsVisible={historyDetailsVisible}
        index={index}
        reportType={reportType}
        reportTypeDesc={reportTypeDesc}
        dayType={dayType}
        viewType={viewType}
        setHistoryDetailsVisible={setHistoryDetailsVisible}
        onClose={() => setHistoryDetailsVisible(false)}
      />
      <div className="d-flex px-2">
        <select
          className="form-control text-font2 mx-2"
          value={dayType}
          onChange={(e) => handleDateChange(e.target.value)}
        >
          {historyDurationList.map((day, index) => (
            <option key={index} value={day}>
              {day} {intl.formatMessage({ id: "days" })}
            </option>
          ))}
        </select>
        {walletState &&
          walletState.topupWithdrawPendingList &&
          walletState.topupWithdrawPendingList
            .topupWithdrawPendingViewTypes && (
            <select
              className="form-control text-font2 mx-2"
              value={viewType}
              onChange={(e) => handleViewTypeChange(e.target.value)}
            >
              {walletState.topupWithdrawPendingList.topupWithdrawPendingViewTypes.map(
                (type, index) => (
                  <option key={index} value={type.viewtype}>
                    {type.viewtypedscp}
                  </option>
                )
              )}
            </select>
          )}
      </div>

      <div className=" mt-2" style={{ minHeight: "600px" }}>
        <div className="d-flex justify-content-center">
          <table style={{ width: "100%" }}>
            <thead className="custom-border">
              <tr>
                <th className="col-3 ps-1 text-font2">
                  {intl.formatMessage({ id: "dateTime" })}
                </th>
                <th className="col-2 text-start text-nowrap text-font2">
                  {intl.formatMessage({ id: "type" })}
                </th>
                <th className="col-2 text-end text-nowrap pe-1 text-font2">
                  {intl.formatMessage({ id: "amount" })}
                </th>
                <th className="col-2 text-start text-nowrap pe-1 text-font2">
                  {intl.formatMessage({ id: "status" })}
                </th>
              </tr>
            </thead>

            <tbody>
              {walletState &&
              walletState.topupWithdrawPendingList &&
              walletState.topupWithdrawPendingList.topupWithdrawPendingLists &&
              walletState.topupWithdrawPendingList.topupWithdrawPendingLists
                .length > 0 ? (
                walletState.topupWithdrawPendingList.topupWithdrawPendingLists.map(
                  (list, index) => (
                    <tr
                      className="custom-border"
                      onClick={() => {
                        setHistoryDetailsVisible(true);
                        setIndex(list.itemid);
                        setReportType(list.reporttype);
                        setReportTypeDesc(list.reportypedscp);
                      }}
                    >
                      <td className="col-3 ps-1 text-font2">
                        {list.requestdate}
                      </td>
                      <td className="col-2 text-start text-nowrap text-font2">
                        {list.reportypedscp}
                      </td>
                      <td className="col-2 text-end text-nowrap pe-1 text-font2">
                        {list.amount}
                      </td>
                      <td className="col-2 text-start text-nowrap pe-1 text-font2">
                        {list.status}
                      </td>
                    </tr>
                  )
                )
              ) : (
                <></>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
