import { Field, Form, Formik } from "formik";
import { LoginTab } from "../../enum/LoginTab";
import { useRef, useState, useEffect } from "react";
import { FiRefreshCw } from "react-icons/fi";
import {
  emailAuthStep1,
  fetchIPAddress,
  fetchVerificationCode,
  getCurrentCountryCode,
  phoneAuthStep1,
  phoneAuthStep2,
} from "../../api/loginApi";
import OTPInput from "react-otp-input";
import { useIntl } from "react-intl";
import {
  EmailInputField,
  ErrorContainer,
  Input,
  PhoneInputField,
} from "../../../common/components/control/InputField";
import { CheckIsNull } from "../../../../utils/CommonHelper";
import Skeleton from "react-loading-skeleton";
import { useAuth } from "../AuthInit";
import { phoneAuthStep1Schema } from "../../yupSchema/phoneAuthSchema";
import { RegisterTypeConst } from "../../../../constants/RegisterTypeConst";

export const LoginPhoneAuthTab = ({
  setLoginTab,
  setSwalProps,
  loginTab,
  tempAuth,
}) => {
  const formikRef = useRef();
  const codeNum = useRef("");
  const intl = useIntl();

  const [isShowPhoneNoError, setIsShowPhoneNoError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [phoneNoErrorDescription, setPhoneNoErrorDescription] = useState("");
  const [phoneCode, setPhoneCode] = useState("my");
  const [selectedNav, setSelectedNav] = useState(RegisterTypeConst.TEL_NO);
  const [isShowGmailError, setIsShowGmailError] = useState(false);
  const [gmailErrorDescription, setGmailNoErrorDescription] = useState("");
  const { saveAuth, setCurrentUser } = useAuth();

  const submitAuthActive = (values) => {
    if (selectedNav === "email") {
      const queryParams = {
        email: values.email,
        memberid: tempAuth.memberid,
        loginid: tempAuth.loginid,
      };
      emailAuthStep1(queryParams).then((resp) => {
        setIsLoading(false);
        if (parseInt(resp.code) === 0) {
          saveAuth({
            ...tempAuth,
            ...(selectedNav === "email"
              ? { email: values.email }
              : { telno: values.phoneNo }),
          });
          setCurrentUser({
            ...tempAuth,
            ...(selectedNav === "email"
              ? { email: values.email }
              : { telno: values.phoneNo }),
          });
          setLoginTab(LoginTab.LOGIN);
        } else {
          setIsShowPhoneNoError(true);
          setIsShowGmailError(true);
          setPhoneNoErrorDescription(resp.message);
          setGmailNoErrorDescription(resp.message);
        }
      });
    } else {
      const queryParams = {
        telno: values.phoneNo,
        memberid: tempAuth.memberid,
        loginid: tempAuth.loginid,
      };

      phoneAuthStep1(queryParams).then((resp) => {
        setIsLoading(false);
        if (parseInt(resp.code) === 0) {
          saveAuth({
            ...tempAuth,
            ...(selectedNav === "email"
              ? { email: values.email }
              : { telno: values.phoneNo }),
          });
          setCurrentUser({
            ...tempAuth,
            ...(selectedNav === "email"
              ? { email: values.email }
              : { telno: values.phoneNo }),
          });
          setLoginTab(LoginTab.LOGIN);
        } else {
          setIsShowPhoneNoError(true);
          setIsShowGmailError(true);
          setPhoneNoErrorDescription(resp.message);
          setGmailNoErrorDescription(resp.message);
        }
      });
    }
  };
  useEffect(() => {
    fetchIPAddress().then((res) => {
      getCurrentCountryCode(res.ip).then((re) => {
        setPhoneCode(re.country.toLowerCase());
        setIsLoading(false);
      });
    });
  }, []);

  return (
    <>
      <div style={{ textAlign: "center" }}>
        <h3 style={{ color: "white" }}>{intl.formatMessage({ id: "auth" })}</h3>
      </div>
      <div className="d-flex mb-2">
        {Object.entries(RegisterTypeConst).map(([key, value]) => (
          <h6
            style={{
              color: selectedNav === value ? "rgb(245, 190, 9)" : "white",
              borderBottom:
                selectedNav === value ? "2px solid rgb(245, 190, 9)" : "",
              padding: "10px",
              cursor: "pointer",
            }}
            className="text-font3"
            onClick={() => {
              if (loginTab === LoginTab.LOGIN_PHONE_AUTH_STEP_1) {
                setSelectedNav(value);
              }
            }}
            key={key}
          >
            {intl.formatMessage({ id: value })}
          </h6>
        ))}
      </div>

      <Formik
        initialValues={{
          phoneNo: "",
        }}
        innerRef={formikRef}
        validationSchema={phoneAuthStep1Schema(intl)}
        enableReinitialize={true}
      >
        {({
          values,
          handleSubmit,
          setFieldValue,
          touched,
          errors,
          setFieldError,
          setFieldTouched,
        }) => (
          <Form>
            {selectedNav === "email" ? (
              <div className="mb-2">
                <label
                  className="mb-2 text-input-label"
                  style={{ color: "white" }}
                >
                  {intl.formatMessage({ id: "email" })}
                </label>

                <EmailInputField
                  placeholder={intl.formatMessage({ id: "email" })}
                  values={values}
                  setFieldValue={setFieldValue}
                  setIsShowGmailNoError={setIsShowGmailError}
                  isShowGmailNoError={isShowGmailError}
                  gmailNoErrorDescription={gmailErrorDescription}
                  name="email"
                  setGmailNoErrorDescription={setGmailNoErrorDescription}
                />
              </div>
            ) : (
              <div className="mb-2">
                <label
                  className="mb-2 text-input-label"
                  style={{ color: "white" }}
                >
                  {intl.formatMessage({ id: "phoneNo" })}
                </label>
                {
                  <PhoneInputField
                    values={values}
                    setFieldValue={setFieldValue}
                    setIsShowPhoneNoError={setIsShowPhoneNoError}
                    isShowPhoneNoError={isShowPhoneNoError}
                    phoneNoErrorDescription={phoneNoErrorDescription}
                    setPhoneNoErrorDescription={setPhoneNoErrorDescription}
                    name="phoneNo"
                    country={phoneCode}
                  />
                }
              </div>
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                paddingTop: "2%",
              }}
            >
              <button
                type="submit"
                disabled={isLoading}
                style={{
                  width: "60%",
                  height: 35,
                  borderRadius: 15,
                  backgroundColor: "rgb(245, 190, 9)",
                  fontWeight: "bold",
                  color: "black",
                  fontSize: "12px",
                }}
                onClick={() => submitAuthActive(values)}
              >
                {isLoading && (
                  <div
                    className="spinner-border"
                    role="status"
                    style={{ scale: "0.75" }}
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                )}
                {intl.formatMessage({ id: "submit" })}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};
