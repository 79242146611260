import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import SweetAlert2 from "react-sweetalert2";
import {
  PaymentMethod,
  PaymentMethodNavConst,
} from "../../../../constants/DepositNavConst";
import { toAbsoluteUrl } from "../../../../utils/CommonHelper";
import { DepositAtmDialog } from "./atmDeposit/DepositAtmDialog";
import { DepositGatewayDialog } from "./gatewayDeposit/DepositGatewayDialog";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Overlay } from "../../../common/components/Overlay";
import Skeleton from "react-loading-skeleton";
// import * as actions from "../../redux/SettingAction";
import { ApiStatusTypeEnum } from "../../../../enumeration/ApiStatusTypeEnum";

export const DepositDialog = ({ companyCode, dismissBtnRef }) => {
  const [swalProps, setSwalProps] = useState({});
  const [paymentMethod, setPaymentMethod] = useState(PaymentMethod.ATM);
  const intl = useIntl();
  const dispatch = useDispatch();

  const settingState = useSelector(({ setting }) => setting, shallowEqual);

  return (
    <div
      style={{
        backgroundColor: "#1c1e23",
        borderRadius: "5px",
      }}
    >
      <SweetAlert2
        customClass="custom-swal"
        onResolve={() => {
          setSwalProps({});
        }}
        {...swalProps}
      />
      <div
        style={{
          color: "white",
          width: "95%",
          margin: "5px auto",
        }}
      >
        <div className="text-input-label mb-2 text-font3">
          {intl.formatMessage({ id: "paymentMethod" })}
        </div>
        <div className="d-flex mb-2">
          {PaymentMethodNavConst.map(
            (nav, index) => (
              <div key={index} className="card cursor-pointer me-4">
                <div
                  style={{
                    background:
                      paymentMethod === nav.title ? "#f5be09" : "#2e323a",
                    display: "flex",
                    width: "80px",
                    height: "80px",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "10px",
                    borderRadius: "5px",
                  }}
                  onClick={() => {
                    setPaymentMethod(nav.title);
                  }}
                >
                  <div
                    className={`d-flex flex-column align-items-center menu-item `}
                  >
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={toAbsoluteUrl(nav.image)}
                        alt="Image Alt Text"
                        style={{
                          width: "30px",
                          height: "30px",
                          filter:
                            paymentMethod === nav.title
                              ? "brightness(0%)"
                              : "brightness(100%)",
                        }}
                      />
                    </span>
                    <div
                      style={{
                        color: paymentMethod === nav.title ? "black" : "white",
                        textAlign: "center",
                      }}
                      className="text-font2"
                    >
                      {intl.formatMessage({ id: nav.title })}
                    </div>
                  </div>
                </div>
              </div>
            )
            // ) : null
          )}
        </div>

        {/* {settingState.depositWalletUI ? (
          paymentMethod === PaymentMethod.ATM ? (
            <DepositAtmDialog
              setSwalProps={setSwalProps}
              companyCode={companyCode}
              dismissBtnRef={dismissBtnRef}
            />
          ) : (
            <DepositGatewayDialog
              setSwalProps={setSwalProps}
              swalProps={swalProps}
              dismissBtnRef={dismissBtnRef}
            />
          )
        ) : (
          <Skeleton className="w-100" style={{ height: "500px" }} />
        )} */}
        {paymentMethod === PaymentMethod.ATM ? (
          <DepositAtmDialog
            setSwalProps={setSwalProps}
            companyCode={companyCode}
            dismissBtnRef={dismissBtnRef}
          />
        ) : (
          <DepositGatewayDialog
            setSwalProps={setSwalProps}
            swalProps={swalProps}
            dismissBtnRef={dismissBtnRef}
          />
        )}
      </div>
    </div>
  );
};
