import React, { useState, useRef, useEffect } from "react";
import { useIntl } from "react-intl";
import { LoginTab } from "../../enum/LoginTab";
import { FiArrowLeft, FiRefreshCw } from "react-icons/fi";
import {
  fetchIPAddress,
  fetchVerificationCode,
  registerStep3,
  register,
} from "../../api/loginApi";
import { Form, Formik, Field } from "formik";
import { RegisterStep3Schema } from "../../yupSchema/registerSchema";
import { CheckIsNull } from "../../../../utils/CommonHelper";
import {
  EmailInputField,
  ErrorContainer,
  Input,
} from "../../../common/components/control/InputField";
import { useAuth } from "../AuthInit";
import { useParams } from "react-router-dom";

export const RegisterEmailTab = ({ setLoginTab, setSwalProps, loginTab }) => {
  const [isLoading, setIsLoading] = useState(false);
  const intl = useIntl();
  const longitude = useRef("");
  const latitude = useRef("");
  const [isShowGmailError, setIsShowGmailError] = useState(false);
  const [verifyImage, setVerifyImageUrl] = useState("");
  const [itemID, setItemID] = useState("");
  const [gmailErrorDescription, setGmailErrorDescription] = useState("");
  const [registerDetail, setRegisterDetail] = useState();
  const { saveAuth, setCurrentUser } = useAuth();
  const ipAddress = useRef("");
  const codeNum = useRef("");
  const formikRef = useRef();
  const dateNow = Date.now();
  const { refferalCode } = useParams();
  const currentLocale = localStorage.getItem("locale");

  const getVerificationCode = () => {
    setVerifyImageUrl("");
    codeNum.current = "";
    fetchVerificationCode().then((resp) => {
      setVerifyImageUrl(resp.imageurl);
      codeNum.current = resp.codenum;
    });
  };
  const handleLanguageChange = (language) => {
    if (sessionStorage.getItem("platform") === "mobile") {
      console.log("update:language");
      localStorage.setItem("locale", language);
      // window.location.reload();
    } else {
      localStorage.setItem("locale", language);
      window.location.reload();
    }
  };
  useEffect(() => {
    getVerificationCode();
  }, []);

  const renderRegisterComponent = () => {
    return (
      <div style={{ width: "100%" }}>
        <div style={{ textAlign: "center" }}>
          <h3 style={{ color: "white" }}>
            {intl.formatMessage({ id: "register" })}
          </h3>
        </div>
        <div className="d-flex"></div>

        <div style={{ margin: "10px", color: "white", textAlign: "center" }}>
          <span
            style={{
              color:
                loginTab === LoginTab.GOOGLE_REGISTER
                  ? "rgb(245, 190, 9)"
                  : "white",
            }}
          >
            1
          </span>
          <div
            style={{
              display: "inline-block",
              width: "15%",
              verticalAlign: "middle",
              borderTop: "1px solid white",
              marginLeft: "5px",
              marginRight: "5px",
            }}
          ></div>
          <span
            style={{
              color:
                loginTab === LoginTab.GOOGLE_REGISTER_PART2
                  ? "rgb(245, 190, 9)"
                  : "white",
            }}
          >
            2
          </span>
          {/* <div
            style={{
              display: "inline-block",
              width: "15%",
              verticalAlign: "middle",
              borderTop: "1px solid white",
              marginLeft: "5px",
              marginRight: "5px",
            }}
          ></div>
          <span
            style={{
              color:
                loginTab === LoginTab.REGISTER_PART_3
                  ? "rgb(245, 190, 9)"
                  : "white",
            }}
          >
            3
          </span> */}
        </div>
        <div style={{ width: "100%" }}>
          {loginTab === LoginTab.GOOGLE_REGISTER ? (
            <Formik
              initialValues={{
                phoneNo: registerDetail ? registerDetail.telno : "",
                referralCode: refferalCode
                  ? refferalCode
                  : registerDetail
                  ? registerDetail.referrerid
                  : "",
                verifyCode: "",
              }}
              innerRef={formikRef}
              //  validationSchema={RegisterStep1Schema(intl)}
              enableReinitialize={true}
              onSubmit={(values, formikHelpers) => {
                // setIsLoading(true);
                // const queryParams = {
                //   email: values.email,
                //   referrerid: values.referralCode,
                //   language: "en",
                // };
                // register(queryParams).then((resp) => {
                //   setIsLoading(false);
                //   getVerificationCode();
                //   formikHelpers.setFieldValue("verifyCode", "");
                //   if (parseInt(resp.code) === 0) {
                //     setRegisterDetail(queryParams);
                //     setLoginTab(LoginTab.GOOGLE_REGISTER_PART2);
                //   } else {
                //     setIsShowGmailError(true);
                //     setGmailErrorDescription(resp.message);
                //   }
                // });
                setIsLoading(true);
                const queryParams = {
                  email: values.email,
                  referrerid: values.referralCode,
                  language: "en",
                };
                setRegisterDetail(queryParams);
                setLoginTab(LoginTab.GOOGLE_REGISTER_PART2);
                setIsLoading(false);
              }}
            >
              {({
                values,
                handleSubmit,
                setFieldValue,
                touched,
                errors,
                setFieldError,
                setFieldTouched,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      paddingTop: "2%",
                    }}
                  >
                    <div
                      style={{
                        paddingLeft: "5%",
                        paddingRight: "5%",
                        width: "90%",
                        boxSizing: "border-box",
                      }}
                    >
                      <label
                        className="mb-2 text-input-label"
                        style={{ color: "white" }}
                      >
                        {intl.formatMessage({ id: "email" })}
                      </label>

                      <EmailInputField
                        placeholder={intl.formatMessage({ id: "email" })}
                        values={values}
                        setFieldValue={setFieldValue}
                        setIsShowGmailNoError={setIsShowGmailError}
                        isShowGmailNoError={isShowGmailError}
                        gmailNoErrorDescription={gmailErrorDescription}
                        name="email"
                        setGmailNoErrorDescription={setGmailErrorDescription}
                      />
                    </div>
                  </div>
                  <div
                    className="mb-2"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      paddingTop: "2%",
                    }}
                  >
                    <div
                      style={{
                        paddingLeft: "5%",
                        paddingRight: "5%",
                        width: "90%",
                        boxSizing: "border-box",
                      }}
                    >
                      <label
                        className="mb-2 text-input-label"
                        style={{ color: "white" }}
                      >
                        {intl.formatMessage({ id: "refferalCode" })}
                      </label>
                      <Field
                        name="referralCode"
                        component={Input}
                        placeholder={intl.formatMessage({ id: "refferalCode" })}
                        autoComplete="off"
                        readOnly={refferalCode ? true : false}
                      />
                    </div>
                  </div>
                  {/* <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      paddingTop: "2%",
                    }}
                  >
                    <div
                      style={{
                        paddingLeft: "5%",
                        paddingRight: "5%",
                        width: "90%",
                        boxSizing: "border-box",
                      }}
                    >
                      <label
                        className="mb-2 text-input-label"
                        style={{ color: "white" }}
                      >
                        {intl.formatMessage({ id: "verificationCode" })}
                      </label>
                      <div className="mb-2">
                        {CheckIsNull(verifyImage) ? (
                          <Skeleton
                            className="col-4"
                            style={{ height: "60px" }}
                          />
                        ) : (
                          <>
                            <img
                              src={verifyImage}
                              alt="verifyImage"
                              className="col-4 me-4"
                              style={{ height: "60px" }}
                            />
                            <FiRefreshCw
                              style={{ color: "white", marginLeft: "5px" }}
                              onClick={() => getVerificationCode()}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </div> */}

                  {/* <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      paddingTop: "2%",
                    }}
                  >
                    <div
                      style={{
                        paddingLeft: "5%",
                        paddingRight: "5%",
                        width: "90%",
                        boxSizing: "border-box",
                      }}
                    >
                      <Field
                        name="verifyCode"
                        component={Input}
                        placeholder={intl.formatMessage({
                          id: "verificationCode",
                        })}
                        autoComplete="off"
                        readOnly={isLoading}
                      />
                    </div>
                  </div> */}

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      paddingTop: "2%",
                    }}
                  >
                    <button
                      type="submit"
                      disabled={isLoading}
                      style={{
                        width: "60%",
                        height: 35,
                        borderRadius: 15,
                        backgroundColor: "rgb(245, 190, 9)",
                        fontWeight: "bold",
                        color: "black",
                        fontSize: "12px",
                      }}
                    >
                      {isLoading && (
                        <div
                          className="spinner-border"
                          role="status"
                          style={{ scale: "0.75" }}
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      )}
                      {intl.formatMessage({ id: "next" })}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          ) : (
            <div style={{ width: "100%" }}>
              <Formik
                initialValues={{
                  username: "",
                  userPassword: "",
                }}
                innerRef={formikRef}
                validationSchema={RegisterStep3Schema(intl)}
                enableReinitialize={true}
                onSubmit={(values, formikHelpers) => {
                  let queryParams = {
                    ...registerDetail,
                    fcmnotificationid: "",
                    username: values.username,
                    password: values.userPassword,
                    itemid: itemID,
                    telno: "",
                  };
                  setIsLoading(true);
                  fetchIPAddress().then((res) => {
                    setIsLoading(false);
                    queryParams = { ...queryParams, ipaddress: res.ip };
                    registerStep3(queryParams).then((resp) => {
                      if (parseInt(resp.code) === 0) {
                        setSwalProps({
                          show: true,
                          icon: "success",
                          title: intl.formatMessage({ id: "success" }),
                          text: intl.formatMessage({ id: "registerSuccess" }),
                          onConfirm: () => {
                            saveAuth(resp);
                            setCurrentUser(resp);
                            setLoginTab(LoginTab.LOGIN);
                          },
                        });
                      } else {
                        // formikHelpers.setFieldError("username", resp.message);
                        setSwalProps({
                          show: true,
                          icon: "error",
                          title: intl.formatMessage({ id: "error" }),
                          text: resp.message,
                        });
                      }
                    });
                  });
                }}
              >
                {({
                  values,
                  handleSubmit,
                  setFieldValue,
                  touched,
                  errors,
                  setFieldError,
                }) => (
                  <Form>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        paddingTop: "2%",
                      }}
                    >
                      <div
                        style={{
                          paddingLeft: "5%",
                          paddingRight: "5%",
                          width: "90%",
                          boxSizing: "border-box",
                        }}
                      >
                        <label
                          className="mb-2 text-input-label"
                          style={{ color: "white" }}
                        >
                          {intl.formatMessage({ id: "username" })}
                        </label>
                        <div className="mb-2">
                          <Field
                            name="username"
                            component={Input}
                            placeholder={intl.formatMessage({ id: "username" })}
                            autoComplete="off"
                            pattern="[a-zA-Z0-9]*"
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          paddingLeft: "5%",
                          paddingRight: "5%",
                          width: "90%",
                          boxSizing: "border-box",
                        }}
                      >
                        <label
                          className="mb-2 text-input-label"
                          style={{ color: "white" }}
                        >
                          {intl.formatMessage({ id: "password" })}
                        </label>
                        <div className="mb-2">
                          <Field
                            name="userPassword"
                            component={Input}
                            placeholder={intl.formatMessage({ id: "password" })}
                            autoComplete="off"
                            type="password"
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          paddingLeft: "5%",
                          paddingRight: "5%",
                          width: "90%",
                          boxSizing: "border-box",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            paddingTop: "2%",
                          }}
                        >
                          <button
                            type="submit"
                            disabled={isLoading}
                            style={{
                              width: "60%",
                              height: 35,
                              borderRadius: 15,
                              backgroundColor: "rgb(245, 190, 9)",
                              fontWeight: "bold",
                              color: "black",
                              fontSize: "12px",
                            }}
                          >
                            {isLoading && (
                              <div
                                className="spinner-border"
                                role="status"
                                style={{ scale: "0.75" }}
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>
                            )}
                            {intl.formatMessage({ id: "register" })}
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          )}
        </div>
        {/* <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "0 auto",
            width: "90%",
            marginTop: 20,
            marginBottom: 20,
            padding: 5,
          }}
        >
          {language !== "en" && (
             
            )}
          <img
            src={`${process.env.PUBLIC_URL}/image/homePage/en.png`}
            style={{ height: "30px", width: "30px", marginLeft: "10px" }}
            onClick={() => handleLanguageChange("en")}
          />
          <img
            src={`${process.env.PUBLIC_URL}/image/homePage/cn.png`}
            style={{ height: "30px", width: "30px", marginLeft: "10px" }}
            onClick={() => handleLanguageChange("zh-cn")}
          />
        </div> */}
        <p
          style={{
            color: "white",
            textAlign: "center",
            fontSize: 13,
            paddingTop: "2%",
          }}
        >
          {intl.formatMessage({ id: "alreadyHaveAccount" })}{" "}
          <button
            onClick={() => {
              setLoginTab(LoginTab.LOGIN);
            }}
            style={{
              cursor: "pointer",
              border: "none",
              background: "none",
              color: "white",
              textDecoration: "underline",
            }}
          >
            {intl.formatMessage({ id: "login" })}
          </button>
        </p>
      </div>
    );
  };

  return (
    <div
      style={{
        backgroundColor: "transparent",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <div style={{ width: "100%" }}>{renderRegisterComponent()}</div>
    </div>
  );
};
