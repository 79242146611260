import { useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";

import SweetAlert2 from "react-sweetalert2";
import { toAbsoluteUrl } from "../../../utils/CommonHelper";
import { TutorialNavTitle } from "../../../constants/NavBarConst";

export const Tutorial = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [swalProps, setSwalProps] = useState({});
  const [tutorialType, setTutorialType] = useState(TutorialNavTitle.ATM);
  const language = localStorage.getItem("locale") || "en";

  const atmENVideoUrl = toAbsoluteUrl(`/video/en/4896ATM.mp4`);
  const atmCNvideoUrl = toAbsoluteUrl(`/video/zh-cn/4896ATM.mp4`);
  const atmMSvideoUrl = toAbsoluteUrl(`/video/ms/4896ATM.mp4`);
  const pgENVideoUrl = toAbsoluteUrl(`/video/en/4896PG.mp4`);
  const pgCNvideoUrl = toAbsoluteUrl(`/video/zh-cn/4896PG.mp4`);
  const pgMSvideoUrl = toAbsoluteUrl(`/video/ms/4896PG.mp4`);

  return (
    <div>
      <SweetAlert2
        onResolve={() => {
          setSwalProps({});
        }}
        backdrop={false}
        {...swalProps}
        customClass="custom-swal"
      />
      <div className="d-flex mb-2">
        {Object.entries(TutorialNavTitle).map(([key, value], index) => (
          <div
            key={index}
            className={`p-2 px-2 cursor-pointer me-2 ws-nowrap rounded text-center ${
              tutorialType === value ? "bg-light-success " : ""
            }`}
            onClick={() => {
              setTutorialType(value);
            }}
            style={{
              background: "rgba(255,255,255,0.1)",
              color: tutorialType === value ? "rgb(245, 190, 9)" : "",
            }}
          >
            {intl.formatMessage({ id: value })}
          </div>
        ))}
      </div>
      <div style={{ width: "100%", maxWidth: "480px", height: "70vh" }}>
        {language === "en" ? (
          tutorialType === "atm" ? (
            <iframe
              src={atmENVideoUrl}
              width="100%"
              height="100%"
              allow="autoplay; encrypted-media"
              allowFullScreen
              title="ATM Tutorial Video"
              frameBorder="0"
              style={{ objectFit: "cover" }}
            ></iframe>
          ) : (
            <iframe
              src={pgENVideoUrl}
              width="100%"
              height="100%"
              allow="autoplay; encrypted-media"
              allowFullScreen
              title="PG Tutorial Video"
              frameBorder="0"
              style={{ objectFit: "cover" }}
            ></iframe>
          )
        ) : language === "ms" ? (
          tutorialType === "atm" ? (
            <iframe
              src={atmMSvideoUrl}
              width="100%"
              height="100%"
              allow="autoplay; encrypted-media"
              allowFullScreen
              title="ATM Tutorial Video"
              frameBorder="0"
              style={{ objectFit: "cover" }}
            ></iframe>
          ) : (
            <iframe
              src={pgMSvideoUrl}
              width="100%"
              height="100%"
              allow="autoplay; encrypted-media"
              allowFullScreen
              title="PG Tutorial Video"
              frameBorder="0"
              style={{ objectFit: "cover" }}
            ></iframe>
          )
        ) : tutorialType === "atm" ? (
          <iframe
            src={atmCNvideoUrl}
            width="100%"
            height="100%"
            allow="autoplay; encrypted-media"
            allowFullScreen
            title="ATM Tutorial Video (CN)"
            frameBorder="0"
            style={{ objectFit: "cover" }}
          ></iframe>
        ) : (
          <iframe
            src={pgCNvideoUrl}
            width="100%"
            height="100%"
            allow="autoplay; encrypted-media"
            allowFullScreen
            title="PG Tutorial Video (CN)"
            frameBorder="0"
            style={{ objectFit: "cover" }}
          ></iframe>
        )}
      </div>
    </div>
  );
};
