import React, { useState, useRef, useEffect } from "react";
import { useIntl } from "react-intl";
import { fetchIPAddress, login, loginTelegram } from "../../api/loginApi";
import { Form, Formik, Field } from "formik";
import { LoginSchema } from "../../yupSchema/loginSchema";
import { CheckIsNull } from "../../../../utils/CommonHelper";
import { useAuth } from "../AuthInit";
import { Input } from "../../../common/components/control/InputField";
import { LoginTab } from "../../enum/LoginTab";
import CryptoJS from "crypto-js";

export const LoginFormTab = ({
  setSwalProps,
  setLoginTab,
  setTempAuth,
  credential,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const { saveAuth, setCurrentUser } = useAuth();
  const intl = useIntl();
  const longitude = useRef("");
  const latitude = useRef("");
  const ipaddress = useRef("");
  const language = localStorage.getItem("locale") || "en";

  useEffect(() => {
    const savedUsername = localStorage.getItem("username");
    if (savedUsername) {
      setRememberMe(true);
    }
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem("platform") === "telegram") {
      localStorage.setItem("locale", "ms");
      const username = sessionStorage.getItem("username");
      const userid = sessionStorage.getItem("userid");
      fetchIPAddress().then((res) => {
        const ipaddress = res.ip;
        localStorage.setItem("ipaddress", ipaddress);
        const queryParams = {
          action: "opentelegram",
          username: username,
          userid: userid,
          language: "ms",
          ipaddress: res.ip,
        };
        loginTelegram(queryParams).then((resp) => {
          saveAuth(resp);
          setCurrentUser(resp);
        });
      });
    }
  }, []);

  useEffect(() => {
    if (credential && credential != "home") {
      const decodeCredential = JSON.parse(
        CryptoJS.AES.decrypt(
          atob(decodeURIComponent(credential)),
          "lott4896#!@lott4896"
        ).toString(CryptoJS.enc.Utf8)
      );
      console.log(decodeCredential);
      if (
        decodeCredential &&
        decodeCredential.username &&
        decodeCredential.password
      ) {
        fetchIPAddress()
          .then((res) => {
            const ipaddress = res.ip;
            localStorage.setItem("ipaddress", ipaddress);
            const queryParams = {
              action: "memberloginV2",
              username: decodeCredential.username,
              password: decodeCredential.password,
              fcmnotificationid: "",
              ipaddress: res.ip,
              email: "",
              uid: "",
              language: language,
            };
            login(queryParams)
              .then((resp) => {
                setIsLoading(false);
                if (parseInt(resp.code) === 0) {
                  if (
                    resp.toactive === "true" &&
                    CheckIsNull(resp.telno) &&
                    CheckIsNull(resp.email)
                  ) {
                    setTempAuth({ ...resp });
                    setLoginTab(LoginTab.LOGIN_PHONE_AUTH_STEP_1);
                  } else {
                    saveAuth(resp);
                    setCurrentUser(resp);
                  }
                } else {
                  setSwalProps({
                    show: true,
                    icon: "error",
                    title: intl.formatMessage({ id: "error" }),
                    text: resp.message,
                  });
                }
              })
              .catch((error) => {
                setIsLoading(false);
              });
          })
          .catch((error) => {
            setIsLoading(false);
          });
      }
    }
  }, [
    credential,
    intl,
    saveAuth,
    setCurrentUser,
    setLoginTab,
    setSwalProps,
    setTempAuth,
  ]);
  useEffect(() => {
    if (sessionStorage.getItem("platform") === "telegram") {
      localStorage.setItem("locale", "ms");
      const username = sessionStorage.getItem("username");
      const userid = sessionStorage.getItem("userid");
    }
  }, []);
  useEffect(() => {
    fetchIPAddress().then((res) => {
      ipaddress.current = res.ip;
      localStorage.setItem("ipaddress", ipaddress.current);
    });
  }, []);

  const handleRememberMeChange = () => {
    setRememberMe(!rememberMe);
  };

  const handleLogin = (userName) => {
    if (rememberMe) {
      localStorage.setItem("username", userName);
    } else {
      localStorage.removeItem("username");
    }
  };
  const handleLanguageChange = (language) => {
    if (sessionStorage.getItem("platform") === "mobile") {
      console.log("update:language");
      localStorage.setItem("locale", language);
    } else {
      localStorage.setItem("locale", language);
      window.location.reload();
    }
  };
  const renderLoginComponent = () => {
    return (
      <div style={{ width: "100%", marginTop: 20 }}>
        <div style={{ width: "100%" }}>
          <Formik
            initialValues={{ username: "", userPassword: "" }}
            enableReinitialize={true}
            validationSchema={LoginSchema(intl)}
            onSubmit={(values, formikHelpers) => {
              setIsLoading(true);
              fetchIPAddress()
                .then((res) => {
                  const ipaddress = res.ip;
                  localStorage.setItem("ipaddress", ipaddress);
                  const queryParams = {
                    action: "memberloginV2",
                    username: values.username,
                    password: values.userPassword,
                    fcmnotificationid: "",
                    ipaddress: res.ip,
                    email: "",
                    uid: "",
                    language: language,
                  };
                  login(queryParams)
                    .then((resp) => {
                      setIsLoading(false);
                      if (parseInt(resp.code) === 0) {
                        if (
                          resp.toactive === "true" &&
                          CheckIsNull(resp.telno) &&
                          CheckIsNull(resp.email)
                        ) {
                          setTempAuth({ ...resp });
                          setLoginTab(LoginTab.LOGIN_PHONE_AUTH_STEP_1);
                        } else {
                          saveAuth(resp);
                          setCurrentUser(resp);
                        }
                      } else {
                        formikHelpers.setFieldValue("userPassword", "");
                        setSwalProps({
                          show: true,
                          icon: "error",
                          title: intl.formatMessage({ id: "error" }),
                          text: resp.message,
                        });
                      }
                    })
                    .catch((error) => {
                      setIsLoading(false);
                    });
                })
                .catch((error) => {
                  setIsLoading(false);
                });
            }}
          >
            {({
              values,
              handleSubmit,
              setFieldValue,
              touched,
              errors,
              setFieldError,
            }) => (
              <Form>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    paddingTop: "2%",
                  }}
                >
                  <div
                    style={{
                      paddingLeft: "5%",
                      paddingRight: "5%",
                      width: "90%",
                      boxSizing: "border-box",
                    }}
                  >
                    <label
                      className="mb-2 text-input-label"
                      style={{ color: "white" }}
                    >
                      {intl.formatMessage({ id: "username" })}
                    </label>
                    <div className="mb-2">
                      <Field
                        name="username"
                        component={Input}
                        placeholder={intl.formatMessage({ id: "username" })}
                        autoComplete="off"
                        pattern="[a-zA-Z0-9]*"
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      paddingLeft: "5%",
                      paddingRight: "5%",
                      width: "90%",
                      boxSizing: "border-box",
                    }}
                  >
                    <label
                      className="mb-2 text-input-label"
                      style={{ color: "white" }}
                    >
                      {intl.formatMessage({ id: "password" })}
                    </label>
                    <div className="mb-2">
                      <Field
                        name="userPassword"
                        component={Input}
                        placeholder={intl.formatMessage({ id: "password" })}
                        autoComplete="off"
                        type="password"
                      />
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    paddingTop: "2%",
                  }}
                >
                  <div
                    className="d-flex justify-content-between"
                    style={{ width: "80%" }}
                  >
                    {/* <div
                      className="cursor-pointer"
                      style={{ color: "white" }}
                      onClick={handleRememberMeChange}
                    >
                      <input
                        type="checkbox"
                        checked={rememberMe}
                        onChange={handleRememberMeChange}
                        className="mx-1"
                      />
                      {intl.formatMessage({ id: "rememberMe" })}
                    </div> */}
                    <p
                      style={{
                        color: "white",
                        textAlign: "start",
                        fontSize: 13,
                      }}
                    >
                      <div
                        style={{
                          cursor: "pointer",
                          border: "none",
                          background: "none",
                          color: "white",
                          textDecoration: "underline",
                        }}
                        onClick={() => {
                          setLoginTab(LoginTab.FORGOT_PASSWORD_PART_1);
                        }}
                      >
                        {intl.formatMessage({ id: "forgetpassword" })}
                      </div>
                    </p>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    paddingTop: "2%",
                  }}
                >
                  <button
                    type="submit"
                    disabled={isLoading}
                    style={{
                      width: "60%",
                      height: 35,
                      borderRadius: 15,
                      backgroundColor: "rgb(245, 190, 9)",
                      fontWeight: "bold",
                      color: "black",
                    }}
                    onClick={handleLogin(values.username)}
                  >
                    {isLoading ? (
                      <div
                        className="spinner-border"
                        role="status"
                        style={{ scale: "0.75" }}
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    ) : (
                      <div>
                        <span style={{ flex: 1, textAlign: "center" }}>
                          {intl.formatMessage({ id: "login" })}
                        </span>
                      </div>
                    )}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "0 auto",
              width: "90%",
              marginTop: 20,
              marginBottom: 20,
              padding: 5,
            }}
          >
            {/* {language !== "en" && (
             
            )} */}
            <img
              src={`${process.env.PUBLIC_URL}/image/homePage/en.png`}
              style={{ height: "30px", width: "30px", marginLeft: "10px" }}
              onClick={() => handleLanguageChange("en")}
            />
            <img
              src={`${process.env.PUBLIC_URL}/image/homePage/cn.png`}
              style={{ height: "30px", width: "30px", marginLeft: "10px" }}
              onClick={() => handleLanguageChange("zh-cn")}
            />
            <img
              src={`${process.env.PUBLIC_URL}/image/homePage/my.png`}
              style={{ height: "30px", width: "30px", marginLeft: "10px" }}
              onClick={() => handleLanguageChange("ms")}
            />
          </div>
          <div
            style={{ display: "flex", justifyContent: "center", marginTop: 20 }}
          >
            <p style={{ color: "white", textAlign: "center", fontSize: 13 }}>
              {intl.formatMessage({ id: "dontHaveAccount" })}{" "}
              <button
                style={{
                  cursor: "pointer",
                  border: "none",
                  background: "none",
                  color: "white",
                  textDecoration: "underline",
                  fontSize: 15,
                }}
                onClick={() => {
                  setLoginTab(LoginTab.REGISTER_OPTION);
                }}
              >
                {intl.formatMessage({ id: "signUp" })}
              </button>
            </p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      style={{
        backgroundColor: "transparent",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <div style={{ textAlign: "center" }}></div>
      <div style={{ width: "100%" }}>{renderLoginComponent()}</div>
    </div>
  );
};
