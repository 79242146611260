import { useRef } from "react";
import { Modal } from "../../../../common/components/Modal";
import { useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../redux/WalletAction";
import { ApiStatusTypeEnum } from "../../../../../enumeration/ApiStatusTypeEnum";
import { AddBankForm } from "./AddBankForm";
import { ResolveArrayNull } from "../../../../../utils/CommonHelper";

export const AddBankDialog = ({ setSwalProps }) => {
  const intl = useIntl();
  const dismissBtnRef = useRef();
  const formikRef = useRef();
  const walletState = useSelector(({ wallet }) => wallet, shallowEqual);

  const dispatch = useDispatch();

  const saveValues = (queryParams) => {
    dispatch(actions.addBankCardList(queryParams)).then((resp) => {
      resp = resp.data;
      if (parseInt(resp.code) === ApiStatusTypeEnum.SUCCESS) {
        setSwalProps({
          show: true,
          icon: "success",
          title: intl.formatMessage({ id: "success" }),
          text: intl.formatMessage({ id: "addBankSuccessfully" }),
          onResolve: () => {
            formikRef.current.resetForm();
            dismissBtnRef.current.click();
            const queryParams = {
              action: "memberbankcardlist",
            };
            dispatch(actions.bankCardList(queryParams));
          },
        });
      } else {
        setSwalProps({
          show: true,
          icon: "error",
          title: intl.formatMessage({ id: "error" }),
          text: resp.message,
        });
      }
    });
  };

  return (
    <Modal
      modalId="addBankModal"
      title={intl.formatMessage({ id: "addBank" })}
      dismissBtnRef={dismissBtnRef}
    >
      <div className="p-3 custom-border-bottom">
        <AddBankForm
          formikRef={formikRef}
          saveValues={saveValues}
          bankList={ResolveArrayNull(walletState.bankCardList?.bankLists)}
        />
      </div>
      <div className="p-3 d-flex justify-content-end align-items-center">
        {walletState.actionsLoading && (
          <div
            className="spinner-border me-2"
            role="status"
            style={{ scale: "0.55" }}
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        )}
        <button
          type="button"
          disabled={walletState.actionsLoading}
          className="btn btn-prim fw-bold px-3 text-font2"
          onClick={() => formikRef.current.submitForm()}
        >
          {intl.formatMessage({ id: "submit" })}
        </button>
      </div>
    </Modal>
  );
};
