import { useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import * as actions from "../../../redux/WalletAction";
import { CheckIsNull } from "../../../../../utils/CommonHelper";
import Skeleton from "react-loading-skeleton";
import { DepositGatewayForm } from "./DepositGatewayForm";
import { ApiStatusTypeEnum } from "../../../../../enumeration/ApiStatusTypeEnum";
import { useNavigate } from "react-router-dom";
import { Dialog } from "@mui/material";

export const DepositGatewayDialog = ({
  setSwalProps,
  companyCode,
  dismissBtnRef,
}) => {
  const dispatch = useDispatch();
  const formikRef = useRef();
  const receiptRef = useRef();
  const intl = useIntl();
  const navigate = useNavigate();
  const [depositToLastGame, setDepositToLastGame] = useState(false);
  const [selectedGateway, setSelectedGateway] = useState(0);
  const [selectedBank, setSelectedBank] = useState(0);
  const [open, setOpen] = useState(false);
  const [newPayment, setNewPayment] = useState("");
  const walletState = useSelector(({ wallet }) => wallet, shallowEqual);

  useEffect(() => {
    const queryParams = {
      action: "topupgatewayui",
    };
    dispatch(actions.topupGatewayUI(queryParams));
  }, []);

  const handleClose = () => {
    setOpen(false);
  };
  const handlNew = () => {
    window.open(newPayment);
    setOpen(false);
  };

  const saveValues = (queryParams) => {
    const newQueryParams = {
      action: "gatewaycreatebill",
      methodcode: "gateway_ok2pay",
      type: "bank",
      bankcode: "",
      amount: queryParams.amount,
      platform: "mobile",
    };
    dispatch(actions.topupGatewayRequest(newQueryParams)).then((resp) => {
      resp = resp.data;
      if (parseInt(resp.code) === ApiStatusTypeEnum.SUCCESS) {
        setNewPayment(resp.paymentlink);
        if (sessionStorage.getItem("platform") === "mobile") {
          const paymentLink = resp.paymentlink;
          console.log(`paymentGateway:${paymentLink}`);
          sessionStorage.setItem("paymentLink", paymentLink);
          // navigate("/setting/deposit/payment");
        } else {
          const isSafari = /^((?!chrome|android).)*safari/i.test(
            navigator.userAgent
          );
          if (isSafari || sessionStorage.getItem("platform") === "telegram") {
            setOpen(true);
          } else {
            window.open(resp.paymentlink);
          }
        }
        formikRef.current.resetForm();
        if (dismissBtnRef) {
          dismissBtnRef.current.click();
        }
      } else {
        setSwalProps({
          show: true,
          icon: "error",
          title: intl.formatMessage({ id: "error" }),
          text: resp.message,
        });
      }
    });
  };

  return (
    <>
      {walletState && walletState.topupGatewayUI && (
        <>
          <div className="text-input-label mb-2 text-font3">
            {intl.formatMessage({ id: "gateway" })}
          </div>
          <div
            className="col-md-3 mb-2"
            style={{ width: "50%", color: "white" }}
          >
            <div
              className={`card cursor-pointer bank-active`}
              style={{
                border: "1px solid #f5be09",
                borderRadius: "5px",
                color: "#f5be09",
                background: "#1c1e23",
              }}
            >
              <div
                className="card-body"
                style={{ background: "rgba(0,0,0,0.2)" }}
              >
                <div
                  className={`d-flex flex-column align-items-center menu-item show fw-bold`}
                >
                  {/* <img
                      src={bank.imageurl}
                      alt={walletState.topupGatewayUI.methoddscp}
                      style={{ width: "100px" }}
                    /> */}
                  <div
                    className="d-flex align-items-center justify-content-center small"
                    style={{ height: "40px" }}
                  >
                    <div className="text-center text-font2">
                      {walletState.topupGatewayUI.methoddscp}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="my-4">
            <DepositGatewayForm
              formikRef={formikRef}
              receiptRef={receiptRef}
              saveValues={saveValues}
            />
          </div>
          <button
            type="button"
            disabled={walletState.actionsLoading}
            style={{ color: "black", backgroundColor: "#f5be09" }}
            className="btn btn-prim fw-bold px-3 w-100 mb-4 mt-2"
            onClick={() => formikRef.current.submitForm()}
          >
            <div className="d-flex align-items-center justify-content-center text-font3">
              {walletState.actionsLoading && (
                <div
                  className="spinner-border text-dark"
                  role="status"
                  style={{ scale: "0.75" }}
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
              {intl.formatMessage({ id: "submit" })}
            </div>
          </button>
          <Dialog open={open} onClose={handleClose}>
            <div
              className="text-font3"
              style={{
                padding: "20px",
                backgroundColor: "#1c1e23",
                color: "white",
              }}
            >
              <p> {intl.formatMessage({ id: "openNewTab" })}</p>
              <div
                style={{ color: "#f5be09", textAlign: "end" }}
                onClick={handlNew}
              >
                {intl.formatMessage({ id: "confirm" })}
              </div>
            </div>
          </Dialog>
        </>
      )}
    </>
  );
};
