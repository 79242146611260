export const LotteryMKTCompanyConst = [
  {
    lotteryUrl: "/image/lotteryCompany/magnum.png?548641",
    lotteryCode: "M",
    lotteryKeyCode: "1",
  },
  {
    lotteryUrl: "/image/lotteryCompany/damacai.png?419561",
    lotteryCode: "P",
    lotteryKeyCode: "2",
  },
  {
    lotteryUrl: "/image/lotteryCompany/toto.png?1567412",
    lotteryCode: "T",
    lotteryKeyCode: "3",
  },
  {
    lotteryUrl: "/image/lotteryCompany/singapore.png?164561",
    lotteryCode: "S",
    lotteryKeyCode: "4",
  },
  {
    lotteryUrl: "/image/lotteryCompany/sabah88.png?261481",
    lotteryCode: "B",
    lotteryKeyCode: "5",
  },
  {
    lotteryUrl: "/image/lotteryCompany/cashweep.png?248861",
    lotteryCode: "W",
    lotteryKeyCode: "6",
  },
  {
    lotteryUrl: "/image/lotteryCompany/sandakan.png?156484",
    lotteryCode: "K",
    lotteryKeyCode: "7",
  },
];

export const LotteryHLCompanyConst = [
  {
    lotteryUrl: "/image/lotteryCompany/gdlotto.png?156411",
    lotteryCode: "H",
    lotteryKeyCode: "8",
  },
  // {
  //   lotteryUrl: "/image/lotteryCompany/nineLotto.png",
  //   lotteryCode: "N",
  //   lotteryKeyCode: "9",
  // },
];
