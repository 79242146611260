import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import SweetAlert2 from "react-sweetalert2";
import { toAbsoluteUrl } from "../../../utils/CommonHelper";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Overlay } from "../../common/components/Overlay";
import Skeleton from "react-loading-skeleton";
import * as actions from "../redux/GroupAction";
import { ApiStatusTypeEnum } from "../../../enumeration/ApiStatusTypeEnum";

import { useLocation } from "react-router-dom";
import { historyDurationList } from "../../../constants/HistoryConst";
import { useAuth } from "../../auth/components/AuthInit";

export const Group = ({}) => {
  const [swalProps, setSwalProps] = useState({});
  const intl = useIntl();
  const dispatch = useDispatch();
  const location = useLocation();
  const { auth } = useAuth();
  const [dayType, setDayType] = useState(1);
  const [showDetails, setShowDetails] = useState(false);
  const [groupStructure, setGroupStructure] = useState(false);
  const [topUpLine, setTopUpLine] = useState(null);
  const groupState = useSelector(({ group }) => group, shallowEqual);

  useEffect(() => {
    const queryParams = {
      action: "membercommissionui",
      days: dayType,
    };
    dispatch(actions.callMemberCommissionUI(queryParams));

    const queryParams1 = {
      action: "membergroup",
      selmemberid: auth.memberid,
      currentlevel: "",
      days: dayType,
    };
    dispatch(actions.callMemberGroup(queryParams1)).then((resp) => {
      resp = resp.data;
      setTopUpLine(resp.memberIDLists);
    });
  }, []);

  const handleDateChange = (value) => {
    setDayType(value);
    const queryParams = {
      action: "membercommissionui",
      days: value,
    };
    dispatch(actions.callMemberCommissionUI(queryParams));

    const queryParams1 = {
      action: "membergroup",
      selmemberid: auth.memberid,
      currentlevel: "",
      days: value,
    };
    dispatch(actions.callMemberGroup(queryParams1)).then((resp) => {
      resp = resp.data;
      setTopUpLine(resp.memberIDLists);
    });
  };

  const handleCallMemberGroup = (memberid, username, nextlevel) => {
    const queryParams = {
      action: "membergroup",
      selmemberid: memberid,
      currentlevel: nextlevel,
      days: dayType,
    };
    dispatch(actions.callMemberGroup(queryParams)).then((resp) => {
      resp = resp.data;
      setTopUpLine(resp.memberIDLists);
    });
  };
  return (
    <div>
      <SweetAlert2
        customClass="custom-swal"
        onResolve={() => {
          setSwalProps({});
        }}
        {...swalProps}
      />

      {groupStructure ? (
        <>
          <div className="mb-2">
            <i
              className="bi bi-chevron-left me-2"
              onClick={() => {
                setGroupStructure(false);
              }}
            />
            {intl.formatMessage({ id: "groupStructure" })}
          </div>

          <nav>
            <ol className="breadcrumb">
              {topUpLine && topUpLine.length > 0 ? (
                <>
                  <li>
                    {topUpLine
                      .slice()
                      .reverse()
                      .map((agent, index) => (
                        <>
                          <span
                            key={index}
                            onClick={() =>
                              handleCallMemberGroup(
                                agent.memberid,
                                agent.username,
                                agent.nextlevel
                              )
                            }
                          >
                            {index > 0 && " / "}
                            <span style={{ textDecoration: "underline" }}>
                              {agent.username}
                            </span>
                          </span>
                        </>
                      ))}
                  </li>
                </>
              ) : (
                <li>
                  <span
                    onClick={() =>
                      handleCallMemberGroup(
                        agent.memberid,
                        agent.username,
                        agent.nextlevel
                      )
                    }
                  >
                    {auth.username}
                  </span>
                </li>
              )}
            </ol>
          </nav>
          {groupState &&
          groupState.memberGroup &&
          groupState.memberGroup.memberGroups &&
          groupState.memberGroup.memberGroups.length > 0 ? (
            groupState.memberGroup.memberGroups.map((agent, index) => (
              <div
                className="card mb-3 text-font4"
                onClick={() => {
                  if (agent.canexpend === "true") {
                    handleCallMemberGroup(
                      agent.memberid,
                      agent.username,
                      agent.nextlevel
                    );
                  }
                }}
              >
                <div className="d-flex justify-content-between mx-1">
                  <div>
                    {agent.username +
                      " (" +
                      intl.formatMessage({ id: "downline" }) +
                      " : " +
                      agent.downlinecount +
                      ", " +
                      "MKT" +
                      " : " +
                      agent.mktbet +
                      ", " +
                      "HL" +
                      " : " +
                      agent.hlbet +
                      ")"}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          <div className="d-flex my-2 justify-content-center w-50">
            <select
              className="form-control text-font2"
              value={dayType}
              onChange={(e) => handleDateChange(e.target.value)}
            >
              {historyDurationList.map((day, index) => (
                <option key={index} value={day}>
                  {day} {intl.formatMessage({ id: "days" })}
                </option>
              ))}
            </select>
            {/* {Object.entries(WalletTypeConst).map(([key, value], index) => (
      <div
        key={index}
        className={`cursor-pointer me-3 rounded ws-nowrap text-center ${
          walletType === value ? " fw-bold " : ""
        }`}
        style={{
          color: walletType === value ? "rgb(245, 190, 9)" : "",
          textDecoration: walletType === value ? "underline" : "",
        }}
        onClick={() => {
          setWalletType(value);
        }}
      >
        {intl.formatMessage({ id: value })}
      </div>
    ))} */}
          </div>
          <div className="card mb-2">
            <div className="d-flex justify-content-between mx-1">
              <div>{intl.formatMessage({ id: "commissionNrebate" })}</div>
              <div
                onClick={() => {
                  setShowDetails((prevState) => !prevState);
                }}
              >
                {showDetails ? (
                  <i className="bi bi-dash" />
                ) : (
                  <i className="bi bi-plus" />
                )}
              </div>
            </div>
            {showDetails && groupState && groupState.memberCommissionUI && (
              <div>
                <table
                  className="w-100"
                  style={{ borderTop: "1px solid white" }}
                >
                  <tr>
                    <td></td>
                    <td>{intl.formatMessage({ id: "personal" })}</td>
                    <td className="text-center">
                      {intl.formatMessage({ id: "firstGen" })}
                    </td>
                    <td className="text-center">
                      {intl.formatMessage({ id: "secondGen" })}
                    </td>
                    <td className="text-center">
                      {intl.formatMessage({ id: "thirdGen" })}
                    </td>
                  </tr>
                  {groupState.memberCommissionUI.memberGroupUI.length > 0 &&
                    groupState.memberCommissionUI.memberGroupUI.map(
                      (type, index) => (
                        <tr>
                          <td>{type.bettype}</td>
                          <td className="text-center">{type.rebate + "%"}</td>
                          <td className="text-center">
                            {type.level1comms + "%"}
                          </td>
                          <td className="text-center">
                            {type.level2comms + "%"}
                          </td>
                          <td className="text-center">
                            {type.level3comms + "%"}
                          </td>
                        </tr>
                      )
                    )}
                </table>
              </div>
            )}
          </div>

          {groupState && groupState.memberCommissionUI ? (
            <>
              <div className="card mb-2">
                <div className="d-flex justify-content-center">
                  <div className="w-50 text-center">
                    <div>
                      <i
                        className="bi bi-person-fill"
                        style={{ fontSize: "25px" }}
                      />
                    </div>
                    <div>{groupState.memberCommissionUI.directmembercount}</div>
                  </div>
                  <div className="w-50 text-center">
                    <div>
                      <i
                        className="bi bi-graph-up-arrow"
                        style={{ fontSize: "25px" }}
                      />
                    </div>
                    <div>
                      {groupState.memberCommissionUI.directgrowpercentage}
                    </div>
                  </div>
                </div>
              </div>
              <div className="card mb-2">
                <div className="mx-1">
                  <div>{intl.formatMessage({ id: "commission" })}</div>
                  <table className="w-100">
                    <tr>
                      <td style={{ width: "40%" }}>
                        {intl.formatMessage({ id: "personalrebate" })}
                      </td>
                      <td style={{ width: "10%" }}>:</td>
                      <td style={{ width: "30%" }}>
                        {
                          groupState.memberCommissionUI.summaryCommission
                            .personalrebate
                        }
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "40%" }}>
                        {intl.formatMessage({ id: "directcommission" })}
                      </td>
                      <td style={{ width: "10%" }}>:</td>
                      <td style={{ width: "30%" }}>
                        {
                          groupState.memberCommissionUI.summaryCommission
                            .directcommission
                        }
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "40%" }}>
                        {intl.formatMessage({ id: "groupcommission" })}
                      </td>
                      <td style={{ width: "10%" }}>:</td>
                      <td style={{ width: "30%" }}>
                        {
                          groupState.memberCommissionUI.summaryCommission
                            .groupcommission
                        }
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div className="card mb-4">
                <div className="mx-1">
                  <div
                  // style={{ textDecoration: "underline" }}
                  >
                    {intl.formatMessage({ id: "commissionGrowUp" })}
                  </div>

                  <table className="w-100">
                    <tr>
                      <td style={{ width: "40%" }}>
                        {intl.formatMessage({ id: "directGrow" })}
                      </td>
                      <td style={{ width: "10%" }}>:</td>
                      <td style={{ width: "30%" }}>
                        {
                          groupState.memberCommissionUI.commissionGrowUp
                            .directgrow
                        }
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "40%" }}>
                        {intl.formatMessage({ id: "groupGrow" })}
                      </td>
                      <td style={{ width: "10%" }}>:</td>
                      <td style={{ width: "30%" }}>
                        {
                          groupState.memberCommissionUI.commissionGrowUp
                            .groupgrow
                        }
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div>
                <button
                  className="btn btn-prim w-100"
                  onClick={() => {
                    setGroupStructure(true);
                  }}
                >
                  {intl.formatMessage({ id: "groupStructure" })}
                </button>
              </div>
            </>
          ) : (
            <></>
          )}
        </>
      )}
    </div>
  );
};
