import { registerLocale } from "react-datepicker";
import { useIntl } from "react-intl";
import { Route, Routes, Navigate } from "react-router-dom";
import { getDateLocale } from "../utils/DateLocale";
import { React } from "react";
import { MasterLayout } from "../modules/common/components/MasterLayout";
import { HomeWrapper } from "../pages/home/HomeWrapper";

const PrivateRoutes = () => {
  const intl = useIntl();
  let locale = intl.locale;
  registerLocale(locale, getDateLocale(locale));

  return (
    <>
      <Routes>
        <Route element={<MasterLayout />}>
          <Route path="home/*" element={<HomeWrapper />} />
          <Route path="*" element={<Navigate to={"/home"} />} />
        </Route>
      </Routes>
    </>
  );
};

export { PrivateRoutes };
