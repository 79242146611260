import { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import SweetAlert2 from "react-sweetalert2";
import * as actions from "../../redux/WalletAction";
import { ApiStatusTypeEnum } from "../../../../enumeration/ApiStatusTypeEnum";
import { Overlay } from "../../../common/components/Overlay";
import { FiArrowLeft } from "react-icons/fi";
import { Link } from "react-router-dom";
import { AddBankDialog } from "./addBank/AddBankDialog";

export const AddBank = ({ selectedBank, setSelectedBank }) => {
  const [swalProps, setSwalProps] = useState({});
  const intl = useIntl();
  const dispatch = useDispatch();
  const walletState = useSelector(({ wallet }) => wallet, shallowEqual);

  useEffect(() => {
    if (
      walletState.bankCardList &&
      walletState.bankCardList.memberBankCardLists.length > 0
    ) {
      setSelectedBank(0); // Default to the first bank card
    }
  }, [walletState.bankCardList, setSelectedBank]);

  const NewBankCard = () => (
    <div
      className="custom-border rounded px-3 py-4 cursor-pointer"
      style={{ background: "rgba(255,255,255,0.02)", margin: "10px" }}
      data-bs-toggle="modal"
      data-bs-target="#addBankModal"
    >
      <div className="d-flex justify-content-center align-items-center mb-3">
        <i className="bi bi-plus-circle text-font4 me-2 mb-1 " />
        <div className="text-font4">
          {intl.formatMessage({ id: "addBank" })}
        </div>
      </div>
      <div className="text-center smaller text-font1">
        {intl.formatMessage({ id: "addBankDescription" })}
      </div>
    </div>
  );

  const handleRemoveBank = (itemid) => {
    const queryParams = {
      action: "removebankcard",
      itemid,
    };
    dispatch(actions.removeBankCardList(queryParams)).then((resp) => {
      resp = resp.data;

      if (parseInt(resp.code) === ApiStatusTypeEnum.SUCCESS) {
        setSwalProps({
          show: true,
          icon: "success",
          title: intl.formatMessage({ id: "success" }),
          text: intl.formatMessage({ id: "deleteBankSuccessfully" }),
          onResolve: () => {
            setSwalProps({});
            const queryParams = {
              action: "memberbankcardlist",
            };
            dispatch(actions.bankCardList(queryParams));
          },
        });
      } else {
        setSwalProps({
          show: true,
          icon: "error",
          title: intl.formatMessage({ id: "error" }),
          text: resp.message,
        });
      }
    });
  };

  const BankCard = ({ bankAccount, index }) => (
    <div
      className="custom-border rounded px-3 py-4"
      style={{
        backgroundColor: "rgba(255,255,255,0.02)",
        width: "94%",
        margin: "10px auto",
        padding: "5px",
        color: "white",
        fontSize: "12px",
        border: selectedBank === index ? "1px solid #f5be09" : "none",
      }}
      onClick={() => setSelectedBank(index)}
    >
      {selectedBank === index ? (
        <>
          <div className="d-flex align-items-center justify-content-between mb-4">
            <div className="fw-bold text-truncate" title={bankAccount.bankname}>
              {bankAccount.bankname}
            </div>
            <div style={{ color: "green", fontSize: "15px" }}>
              <i className="bi bi-check-circle" />
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <div
              className="fw-bold text-truncate"
              title={bankAccount.accountnum}
            >
              {bankAccount.accountnum}
            </div>
            <div>
              <i
                className="bi bi-trash text-danger cursor-pointer"
                onClick={() => {
                  setSwalProps({
                    show: true,
                    showCancelButton: true,
                    showConfirmButton: true,
                    confirmButtonText: intl.formatMessage({ id: "confirm" }),
                    denyButtonText: intl.formatMessage({ id: "cancel" }),
                    icon: "info",
                    title: intl.formatMessage({ id: "alert" }),
                    text: intl.formatMessage({ id: "confirmRemoveBank" }),
                    onResolve: () => {
                      setSwalProps({});
                      handleRemoveBank(bankAccount.itemid);
                    },
                  });
                }}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="d-flex align-items-center justify-content-between mb-4">
            <div className="fw-bold text-truncate" title={bankAccount.bankname}>
              {bankAccount.bankname}
            </div>
            <div>
              <i
                className="bi bi-trash text-danger cursor-pointer"
                onClick={() => {
                  setSwalProps({
                    show: true,
                    showCancelButton: true,
                    showConfirmButton: true,
                    confirmButtonText: intl.formatMessage({ id: "confirm" }),
                    denyButtonText: intl.formatMessage({ id: "cancel" }),
                    icon: "info",
                    title: intl.formatMessage({ id: "alert" }),
                    text: intl.formatMessage({ id: "confirmRemoveBank" }),
                    onResolve: () => {
                      setSwalProps({});
                      handleRemoveBank(bankAccount.itemid);
                    },
                  });
                }}
              />
            </div>
          </div>
          <div className="text-truncate">{bankAccount.accountnum}</div>
        </>
      )}
    </div>
  );

  const NewBankCardList = () => {
    if (walletState.bankCardList) {
      let indents = [];
      for (
        var i = 0;
        i < 3 - walletState.bankCardList.memberBankCardLists.length;
        i++
      ) {
        indents.push(<NewBankCard key={i} />);
      }
      return <>{indents}</>;
    } else {
      return <></>;
    }
  };

  return (
    <div>
      <SweetAlert2
        customClass="custom-swal"
        onResolve={() => {
          setSwalProps({});
        }}
        {...swalProps}
      />
      {walletState && walletState.bankCardList && (
        // <AddBankDialog setSwalProps={setSwalProps}  fullName={walletState.withdrawUIList.fullname}/>
        <AddBankDialog setSwalProps={setSwalProps} />
      )}

      <div
        style={{
          backgroundColor: "#1c1e23",
          width: "95%",
          margin: "10px auto",
          padding: "5px",
          color: "white",
          fontSize: "12px",
          borderRadius: "5px",
        }}
      >
        <div className="collapse show" id="collapseExample">
          <div>
            {walletState &&
              walletState.bankCardList &&
              walletState.bankCardList.memberBankCardLists.map(
                (bankAccount, index) => (
                  <BankCard
                    key={index}
                    bankAccount={bankAccount}
                    index={index}
                  />
                )
              )}
            <NewBankCardList />
          </div>
        </div>
      </div>
    </div>
  );
};
